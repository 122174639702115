@import './variables';

.link {
    color: $defaultGrayColor;
    font-size: 0.875em;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.link-hint {
    border: none;
    cursor: pointer;
    padding: 4px;
    margin: -6px 0;
    width: 28px;
    height: 28px;
    margin-bottom: 2px;
    display: inline-block;
    transition: background-color 0.3s;
    background: transparent;
    border-radius: 50%;

    svg {
        fill: #849cc0;
    }

    &:hover {
        background-color: #d6ebf7;

        svg {
            fill: #3597d4;
        }
    }
    
    &__tooltip {
        max-width: 200px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);
    
        &::before,
        &::after {
            content: unset !important;
        }
    }
}