@import '../../../styles/variables';
@import '../../../styles/mixins';

.selected {
    margin: 0 -15px;
    padding: 0 19px;

    @include clearfix;
    &__body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    &__col {
        padding: 0 15px;
        position: relative;
        &:last-child {
            padding-right: 0;
        }
        &--wide {
            width: 66.666%;
        }

        &--50 {
            width: 50%;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 1px;
            width: 1px;
            height: 100%;
        }
    }
    &__orange {
        &:before {
            background-color: #FF7A00;
        }
    }
    &__green {
        &:before {
            background-color: #10BC6A;
        }
    }
    &__purple {
        &:before {
            background-color: #7427C1;
        }
    }
    &__row {
        margin: 4px 0;
        &:first-child {
            margin-top: 0;
        }
        &--contacts {
            grid-template-columns: 1fr 116px 30px;
        }
    }

    &__value {
        &--wide {
            width: 70%;
        }

        &--comment {
            -webkit-line-clamp: 5;
        }
    }

    &__name {
        &--wide {
            width: 30%;
        }
    }


    &__bold {
        font-weight: 700;
    }

    &__semibold {
        font-weight: 600;
    }

    &__phone {
        color: $defaultColor !important;
        font-size: 1em !important;
        line-height: 1.2;
        font-weight: 600;
    }

    &__contacts {
        color: $defaultGrayColor !important;
        font-size: 1em !important;
        line-height: 1.2;
        font-weight: 400;

        > span {
            margin: 0 3px;
        }
    }

    &__call {
        display: inline-block;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        background-color: transparent;
        border: unset;
        cursor: pointer;

        svg {
            transition: fill $baseTransitionTime;
            stroke: $defaultGrayColor;
            fill: #fff;
        }

        &:hover {
            svg {
                stroke: $highlightBlue;
            }
        }
    }
}

.meta {
    padding: 3px 20px 3px 12px;
    color: $grayBlueColor;
    font-weight: 500;
    font-size: 0.625em;
    border-top: 1px solid $defaultBorderColor;
}