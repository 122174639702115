@import '../../../styles/variables';

.container {
    position: relative;
    height: 100%;
    padding: 30px 45px 45px;
}

.title {
    margin-top: 0;
    margin-bottom: 20px;
    color: $defaultColor;
    font-weight: 600;
    font-size: 1.125em;
    line-height: 1.2;
    text-align: left;

    .title {
        &__phone {
            color: $defaultColor;
            text-decoration: underline;
        }
    }
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.content {
    max-height: calc(100vh - 230px);
    overflow-y: auto;
}

.similar {
    &__tab {
        &-list {
            min-height: 28px;
        }
    }

    &__content {
        position: relative;
        height: calc(100% - 28px);
    }

    &__message {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 420px;
        padding: 45px 80px;
        text-align: center;
        transform: translate(-50%, -50%);
    }
}

.message {
    &__title {
        margin: 0;
        color: $defaultColor;
        font-weight: 600;
        font-size: 1.125em;
        line-height: 1.2;
    }

    &__text {
        margin-top: 8px;
        margin-bottom: 24px;
        color: $defaultColor;
        font-size: 0.875em;
        line-height: 1.1;
        text-align: center;
    }
}

.similar-list {
    width: 235px;
    padding: 5px;
    font-size: 1em;

    &__list {
        margin: 5px 0;
        padding: 0 0 0 10px;
        list-style-type: none;
    }

    &__title {
        font-weight: 900;
    }

    &__item {
        padding: 0;

        &::before {
            content: '- ';
        }
    }
}
