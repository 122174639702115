@import '../../styles/variables';

.button {
    &__icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        transition: fill $baseTransitionTime;
    }
}
