
@import '../../../styles/variables';

.register {
    &__select {
        margin-top: 16px;
    }

    &__user {
        padding-top: 1px;

        & > * {
            &:first-child {
                margin-top: -30px;
            }
        }
    }

    &__company {
        padding-top: 1px;
        padding-bottom: 30px;

        & > * {
            &:first-child {
                margin-top: -40px;
            }
        }
    }

    &__finish {
        padding: 1em;
        background-color: $loginInfo;
        border: 1px solid $loginInfoBorder;
        border-radius: $defaultBorderRadius;
    }

    &__warning {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1em;
        margin: 0.715em 0 1.715em 0;
        padding: 0.715em 1em;
        font-size: 0.875em;
        font-weight: 600;
        color: $highlightOrange;
        border: 2px solid $highlightOrange;
        border-radius: $defaultBorderRadius;
    }

    &__counter {
        margin-top: 0.5em;
        margin-bottom: -2em;
        color: $highlightRed;
        font-size: 0.75em;
        text-align: center;
        font-weight: 600;
        opacity: 0;
        transition: opacity $baseTransitionTime;

        &--active {
            opacity: 1;
        }
    }
}

.credentials {
    font-size: 1.125em;

    &:not(:last-child) {
        margin-bottom: 1em;
    }

    &__label {
        font-weight: 700;
        margin-bottom: 0.25em;
    }

    &__view {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.5em;
        padding: 3px;
        background-color: $inputBackground;
        border-radius: 100%;

        svg {
            stroke: currentColor;
        }
    }
}

.reset {
    min-height: 250px;

    &__header {
        font-size: 0.75em;
        line-height: 1.6335;
    }

    &__attention {
        margin-bottom: 2em;
        font-size: 0.875em;
        text-align: center;
    }
}
