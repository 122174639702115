@import '../../styles/mixins';

.tag-list {
    @include clearfix;

    &__item {
        float: left;
    }

    &--md {
        margin: 0 -4px;

        .tag-list {
            &__item {
                padding: 4px;
            }
        }
    }

    &--sm {
        margin: 0 -2px;

        .tag-list {
            &__item {
                padding: 2px;
            }
        }
    }

    &--lg {
        margin: 0 -6px;

        .tag-list {
            &__item {
                padding: 6px;
            }
        }
    }
}
