.radius-search {
  &__map {
    height: calc(100% - 151px);
    margin: 20px 0;

    :global(.yandex-map) {
      height: 100%;
    }
  }

  &__label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__input {
    gap: 10px;
    display: flex;

    button {
      padding: 9px;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__container {
    height: 100%;
    padding: 24px 45px;
  }

  &__handle {
    top: 0;
    left: 0;
    right: 0;
    height: 43px;
    position: absolute;

    button {
      top: 10px;
      right: 10px;
      position: absolute;
    }
  }
}