@import '../../styles/variables';

.tag {
    position: relative;
    display: block;
    font-size: 0;

    &__label {
        display: inline-block;
        line-height: 1.5;
        background-color: transparent;
        border: 1px solid $inputBorder;
        border-radius: $defaultBorderRadius;
        cursor: pointer;
        transition:
            border-color $baseTransitionTime,
            background-color $baseTransitionTime,
            color $baseTransitionTime,
            opacity $baseTransitionTime;

        &:hover {
            border-color: $inputBorderHover;
        }
    }

    &--sm {
        .tag {
            &__label {
                padding: 0 5px;
                font-size: 0.625rem;
            }
        }
    }

    &--md {
        .tag {
            &__label {
                padding: 2px 11px;
                font-size: 0.625rem;
            }
        }
    }

    &--lg {
        .tag {
            &__label {
                padding: 4px 15px;
                font-size: 0.75rem;
            }
        }
    }

    &__input {
        position: absolute;
        left: -9999px;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;

        &:checked,
        &--checked {
            & + .tag {
                &__label {
                    color: $inputBackground;
                    background-color: $inputBorderFocus;
                    border-color: $inputBorderFocus;
                }
            }
        }

        &:disabled {
            & + .tag {
                &__label {
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }
    }
}
