.page-title {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 0.75em;

    &__title {
        display: inline-block;
        font-weight: 700;
        font-size: 1.5em;
        line-height: 1;
        vertical-align: middle;
    }

    &__icon {
        display: inline-block;
        margin-left: 15px;
        vertical-align: middle;
        transform: translate(0, 2px);

        svg {
            fill: transparent;
        }
    }
}
