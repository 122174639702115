.draggable-tooltip-container {
    .dragable-tooltip {
        position: absolute;
        z-index: 5;

        &.react-draggable {
            #drag-handler {
                cursor: grab;
            }
        }
    
        &.react-draggable-dragging {
            #drag-handler {
                cursor: grabbing;
            }
        }
    }
    
    .tooltip-content {
        opacity: 1 !important;
        padding: 0 20px 15px 20px !important;
        box-shadow: rgba(0, 0, 0, .6) 1px 1px 8px;
    }
}
