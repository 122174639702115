@import '../../styles/variables';

.dropdown {
    position: relative;
    font-size: 0.875em;

    &__trigger {
        position: relative;
        display: block;
        width: 100%;
        padding: 6px 10px;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: $defaultBorderRadius;
        cursor: pointer;
        transition:
            background-color $baseTransitionTime,
            border-color $baseTransitionTime;

        &::after {
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            margin-left: -9px;
            border-color: $inputBorder transparent transparent transparent;
            border-style: solid;
            border-width: 4px 9px 0 9px;
            opacity: 0;
            transition: opacity $baseTransitionTime;
            content: '';
        }

        &:disabled {
            opacity: 0.3;
            pointer-events: none;

            &:read-only {
                opacity: 1;
            }
        }

        &:hover {
            border-color: $inputBorder;
        }
    }

    &__content {
        position: absolute;
        top: calc(100% + 10px);
        left: -9999px;
        z-index: 5;
        background-color: #fff;
        border: 1px solid $inputBorder;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 8px rgba(19, 25, 28, 0.1);
        opacity: 0;
        transition:
            left 0s $baseTransitionTime,
            top $baseTransitionTime,
            opacity $baseTransitionTime;

        &--headless {
            position: static;
        }
    }

    &--open {
        .dropdown {
            &__trigger {
                background-color: $defaultContentBackground;
                border-color: $inputBorder;

                &::after {
                    opacity: 1;
                }
            }

            &__content {
                top: calc(100% + 5px);
                left: 0;
                opacity: 1;
                transition:
                    left 0s,
                    top $baseTransitionTime,
                    opacity $baseTransitionTime;
            }
        }

        &.dropdown {
            &--right {
                .dropdown {
                    &__content {
                        right: 0;
                        left: auto;
                        transition:
                            left 0s,
                            top $baseTransitionTime,
                            opacity $baseTransitionTime;
                    }
                }
            }
        }
    }

    &--right {
        &--content {
            right: 9999px;
            left: auto;
            transition:
                right 0s $baseTransitionTime,
                top $baseTransitionTime,
                opacity $baseTransitionTime;
        }
    }
}
