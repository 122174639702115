@import '../../styles/variables';

.checkbox {
    position: relative;
    display: block;

    &__label {
        position: relative;
        display: block;
        line-height: 1.35;
        cursor: pointer;
        opacity: 1;
        transition: opacity $baseTransitionTime;
        text-align: left;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $inputBackground;
            border: 1px solid $inputBorder;
            border-radius: $defaultBorderRadius;
            transition: border-color $baseTransitionTime, background-color $baseTransitionTime;
            content: '';
        }

        &:hover {
            &::before {
                border-color: $inputBorderHover;
            }
        }
    }

    &--sm {
        padding: 2px 0;

        .checkbox {
            &__label {
                padding-left: 12px;
                font-size: 0.625em;

                &::before {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    &--md {
        .checkbox {
            &__label {
                padding-left: 16px;
                font-size: 0.75em;

                &::before {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &--lg {
        .checkbox {
            &__label {
                padding-top: 2px;
                padding-bottom: 2px;
                padding-left: 20px;
                font-size: 0.75em;

                &::before {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &--children {
        &.checkbox {
            &--sm {
                .checkbox {
                    &__label {
                        padding-left: 18px;
                    }
                }
            }

            &--md {
                .checkbox {
                    &__label {
                        padding-left: 24px;
                    }
                }
            }

            &--lg {
                .checkbox {
                    &__label {
                        padding-left: 30px;
                    }
                }
            }
        }
    }

    &__input {
        position: absolute;
        left: -9999px;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;

        &:checked,
        &--checked {
            & + .checkbox {
                &__label {
                    &::before {
                        background-color: $checkboxChecked;
                        background-image: url('../../images/checked-icon.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 65%;
                        border-color: $checkboxChecked;
                    }
                }
            }
        }

        &:disabled {
            & + .checkbox {
                &__label {
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }

        &--readonly {
            & + .checkbox {
                &__label {
                    pointer-events: none;
                    opacity: 1 !important;
                }
            }
        }
    }
}
