@import '../../styles/variables';
@import '../../styles/mixins';

.context {
    &-menu {
        padding: 5px 0;
        font-size: 0.75em;
        background-color: $defaultBackground;
        border-radius: $defaultBorderRadius;
        box-shadow: 0px 0px 4px rgba(69, 109, 121, 0.25);
    }

    &-menu-item {
        padding: 5px 15px;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        white-space: nowrap;

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: $inputBorder;
        }

        &__icon,
        &__value {
            display: inline-block;
            vertical-align: middle;
        }

        &__icon {
            width: 16px;
            margin-right: 5px;
            transform: translateY(2px);
            
            svg {
                fill: transparent;
            }
        }

        &--parent {
            outline: none;
            padding-right: 28px;

            &::after {
                display: inline-block;
                vertical-align: middle;
                width: 0;
                height: 0;
                margin-left: 10px;
                border-style: solid;
                border-width: 4px 0 4px 6px;
                border-color: transparent transparent transparent $highlightBlue;
                transform: translateY(1px);
                content: '';

                position: absolute;
                right: 8px;
                top: 10px;
            }
        }

        &--disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &-sub-menu {
        display: none;

        &:global(.react-contextmenu--visible) {
            @extend .context-menu;

            font-size: 1em;
            display: block;
        }
    }
}

:global(.react-contextmenu-item--active) {
    .context-menu-item {
        background-color: $inputBorder;
    }
}

:global(.react-contextmenu-submenu) {
    &:focus {
        outline: none;
    }
}
