@import '../../../styles/variables';

.root {
    // For correct display multiple tabs popup -->
    position: relative;
    min-height: 100vh;
    // For correct display multiple tabs popup -->
    overflow: hidden;
    background-color: $defaultContentBackground;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        margin-top: 0;
        margin-bottom: 32px;
        font-weight: 600;
        font-size: 1.5em;
        text-align: center;
    }
}

.form {
    width: 600px;
    padding: 40px;
    background-color: white;

    &__debt {
        color: $highlightRed;
        font-size: 14px;
        text-align: left;

        b {
            font-weight: 600;
        }
    }
    
    &__fields {
        text-align: left;
    }

    &__preload {
        display: flex;
        align-items: center;
        justify-content: center;
        
        svg {
            margin-left: 10px;
        }
    }

    &__submit {
        margin-top: 24px;
        
        button {
            width: 100%;
        }
    }

    &__forgot {
        margin-top: 8px;

        button {
            cursor: pointer;
            border: none;
            padding: 0;
            background: transparent;
        }
    }
}

.payment-dialog {
    form {
        width: auto;
        padding: 0;
        padding-bottom: 30px;
    }

    &__status {
        text-align: center;
        margin-top: 15px;
        font-weight: 600;
        margin-bottom: 33px;
    }

    &__icon {
        margin-top: 30px;
    }

    &__loader {
        margin: 25px auto
    }
}