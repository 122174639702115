@import '../../styles/mixins';
@import '../../styles/variables';
@import '../Input/Input.module.scss';

.datepicker {
    @extend .input;

    position: relative;
    display: block;

    &__icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);

        svg {
            fill: $inputBorderFocus;
        }
    }

    &__clear {
        position: absolute;
        top: 50%;
        padding: 3px;
        background-color: transparent;
        border: unset;
        outline: none;
        transform: translate(0, -50%);
        cursor: pointer;

        &--hidden {
            display: none;
        }

        svg {
            display: block;
            transition: fill $baseTransitionTime;
            pointer-events: none;
            fill: $defaultGrayColor;
        }

        &:hover {
            svg {
                fill: #15a3ef;
            }
        }
    }

    &::after {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 0;
        height: 0;
        border-color: $inputBorderFocus transparent transparent transparent;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-radius: 2px;
        transform: translate(0, -50%);
        content: '';
    }

    &--opened {
        &::after {
            margin-top: -3px;
            transform: rotate(180deg);
        }
    }

    &--sm {
        @extend .input--sm;

        .datepicker {
            &__icon {
                right: 25px;
            }
        }
    }

    &--md {
        @extend .input--md;

        .datepicker {
            &__icon {
                right: 25px;
            }
        }
    }

    &--lg {
        @extend .input--lg;

        .datepicker {
            &__icon {
                right: 25px;
            }
        }
    }

    input {
        padding-right: 50px;

        &:disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    &-wrapper {
        position: relative;

        &--sm {
            .datepicker {
                &__clear {
                    right: 43px;
                }
            }
        }

        &--md {
            .datepicker {
                &__clear {
                    right: 45px;
                }
            }
        }

        &--lg {
            .datepicker {
                &__clear {
                    right: 47px;
                }
            }
        }
    }
}

.calendar-header {
    position: relative;
    padding: 13px;
    font-size: 0.875em;
    text-align: center;
    text-transform: uppercase;

    @include clearfix;

    &__nav {
        position: absolute;
        top: 50%;
        margin: 0;
        padding: 10px;
        background-color: transparent;
        border: unset;
        transform: translate(0, -50%);
        cursor: pointer;

        .calendar-header {
            &__icon {
                fill: $inputBorderFocus;
            }
        }

        &--prev {
            left: -14px;

            .calendar-header {
                &__icon {
                    transform: rotate(180deg);
                }
            }
        }

        &--next {
            right: -14px;
        }
    }

    &__month,
    &__year {
        display: inline-block;
    }

    &__month {
        padding-right: 12px;
        text-align: right;
    }

    &__year {
        padding-left: 12px;
        text-align: left;
    }
}

:global(.react-datepicker) {
    padding: 0 20px 10px 20px;
    font-size: 1rem;
    border: unset;
    border-radius: $defaultBorderRadius;
    box-shadow: 0 0 4px $inputBoxShadow;

    &-popper {
        z-index: 401;

        &[data-placement^="bottom"] {
            margin-top: 5px;
        }

        &[data-placement^="top"] {
            margin-bottom: 5px;
        }
    }

    &-wrapper,
    &__input-container {
        display: block;
    }

    &__header {
        padding: 0;
        background-color: #fff;
        border-bottom: 1px solid $inputBorder;
    }

    &__day-names {
        @include clearfix;
    }

    &__day-name,
    &__day {
        display: block;
        float: left;
        width: calc(100% / 7);
        margin: 0;
        font-size: 0.875em;
    }

    &__day {
        min-width: 28px;
        line-height: 2;
        outline: none;

        &:hover {
            background-color: rgba($inputBorderFocus, 0.2);
            border-radius: $defaultBorderRadius;
        }

        &--outside-month {
            opacity: 0;
            pointer-events: none;
        }

        &--selected {
            color: $inputBackground;
            background-color: rgba($inputBorderFocus, 1);
            border-radius: $defaultBorderRadius;

            &:hover {
                background-color: rgba($inputBorderFocus, 1);
                border-radius: $defaultBorderRadius;
            }
        }
    }

    &__day-name {
        color: $defaultGrayColor;
    }

    &__month {
        margin: 0;
        padding-top: 5px;
    }

    &__week {
        @include clearfix;
    }
}
