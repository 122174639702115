@import '../../../styles/variables';

.container {
    position: relative;
    height: 100%;
    padding: 30px 45px 45px;
}

.title {
    margin-top: 0;
    margin-bottom: 20px;
    color: $defaultColor;
    font-weight: 600;
    font-size: 1.125em;
    line-height: 1.2;
    text-align: left;

    .title {
        &__phone {
            color: $defaultColor;
            text-decoration: underline;
        }
    }
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.content {
    position: relative;
    max-height: calc(100vh - 230px);
    margin-bottom: 15px;
}

.notepad {
    &__menu {
        position: relative;
    }

    &__tab {
        &-list {
            min-height: 28px;

            &--parents {
                position: absolute;
                top: 202px;
                left: 0;
                z-index: 2;
                direction: rtl;
                transform: rotate(-90deg);
                transform-origin: bottom left;

                .notepad {
                    &__tab {
                        &--parent {
                            &::after {
                                right: -5px;
                                left: unset;
                            }

                            &::before {
                                right: -1px;
                                left: unset;
                            }

                            &:first-child {
                                > * {
                                    border-top-left-radius: 0;
                                    border-top-right-radius: 3px;
                                }
                            }

                            &:last-child {
                                > * {
                                    border-right: 0;
                                    border-left: 1px solid #d3e0ec;
                                    border-top-left-radius: 3px;
                                    border-top-right-radius: 0;
                                }
                            }
                        }

                        &--active {
                            &:first-child {
                                &::after {
                                    right: unset;
                                    left: -5px;
                                }

                                &:last-child::after {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__content {
        position: relative;
        height: calc(100% - 28px);
    }

    &__message {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 420px;
        padding: 45px 80px;
        text-align: center;
        background-color: #fff;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);
        transform: translate(-50%, -50%);
    }

    &__delete {
        position: absolute;
        top: 100%;
        right: -30px;
        padding: 0;
        color: $defaultGrayColor;
        font-size: 0.75em;
        text-decoration: underline;
        background-color: transparent;
        border: unset;
        cursor: pointer;
        transition: color $baseTransitionTime;

        &:hover {
            color: $defaultColor;
        }
    }

    &__delete-objects {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.message {
    &__title {
        margin: 0;
        color: $defaultColor;
        font-weight: 600;
        font-size: 1.125em;
        line-height: 1.2;
    }

    &__text {
        margin-top: 8px;
        margin-bottom: 24px;
        color: $defaultColor;
        font-size: 0.875em;
        line-height: 1.1;
        text-align: center;
    }
}

.approving {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: $defaultBackground;

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        font-weight: 600;
        font-size: 1.5em;
        text-align: center;
    }

    &__box {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 300px;
        transform: translate(-50%, -50%);
    }

    &__description {
        margin-top: 15px;
        margin-bottom: 50px;
        font-weight: normal;
        font-size: 0.5em;
    }

    &__buttons {
        max-width: 258px;
        margin: 28px auto 0;
    }

    &__button {
        margin-top: 16px;
        font-weight: 400;
        font-size: 0.5em;
    }

    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
        color: $defaultGrayColor;

        svg {
            fill: $defaultGrayColor;
        }
    }
}
