@import '../../../styles/variables';
@import '../../../styles/mixins';

.groups {
    margin-left: 5px;
    font-size: 0.75em;

    &__title {
        display: inline-block;
        margin-bottom: -1px;
        padding: 5px 10px;
        font-weight: 500;
        font-size: 1em;
        line-height: 1.4;
        background-color: $defaultBackground;
        border: 1px solid $defaultBorderColor;
        border-bottom-color: $defaultBackground;
        border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;
    }

    &__list {
        min-width: 350px;
        padding: 8px 17px;
        font-size: 1rem;
        background-color: $defaultBackground;
        border: 1px solid $defaultBorderColor;
        border-bottom: unset;
        border-radius: 0 $defaultBorderRadius 0 0;

        @include clearfix;
    }

    &__item {
        float: left;
        width: 33.333%;
        padding: 4px 0;

        &--auto {
            width: auto;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
