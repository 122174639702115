// defaults
$defaultFontFamily: golos;
$fallbackFontFamily: sans-serif;
$defaultColor: #161c24;
$defaultFontSize: 16px;
$defaultBackground: #fff;
$defaultContentBackground: #f4f7f8;
$defaultBorderRadius: 3px;
$defaultBorderColor: #d3e0ec;
$defaultBorderHalfColor: #e9f0f5;
$defaultGrayColor: #a2b0bd;

// colors
$grayBlueColor: #62819d;

// transition
$baseTransitionTime: 0.3s;

// inputs
$inputBorder: #d3e0ec;
$inputBorderHover: #9fc7eb;
$inputBorderFocus: #15a1ef;
$inputBoxShadow: rgba(69, 109, 121, 0.25);
$inputBackground: #fff;
$inputButtonColor: #849cc0;
$inputButtonColorHover: #3597d4;
$inputButtonBackgroundHover: #d6ebf7;
$checkboxChecked: #10bc6a;
$rightsBackground: #e7f1f3;

// error
$error: #e20d40;

// pagination
$paginationBackground: #fff;
$paginationBorder: #dae3f4;
$paginationActive: #15a1ef;

// highlihits
$highlightGreen: #10bc6a;
$highlightOrange: #ff7a00;
$highlightYellow: #ffc700;
$highlightBlue: #15a1ef;
$highlightRed: #e20d40;
$highlightGray: #7a7d81;
$highlightPurple: #811fcf;

// table
$rowOddBackground: #f4f8fb;
$rowHoverBackground: #d7ecf2;

$loginInfo: #fff2d0;
$loginInfoBorder: #d1c196;
