@import '../../../styles/variables';

.content {
    width: 100%;
    overflow: hidden;
}

.preloader {
    position: absolute;
    top: 50%;
    left: -9999px;
    z-index: 2;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition:
        left 0s $baseTransitionTime,
        opacity $baseTransitionTime;

    &--loading {
        left: 50%;
        opacity: 1;
        transition:
            left 0s,
            opacity $baseTransitionTime;
    }
}
