@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../components/CustomTabs/CustomTabs.module.scss';

.page-view {
    &__menu {
        position: relative;

        &-title {
            @extend .tab-child;

            padding: 5px 25px;
            line-height: 1.1;
        }

        &-item {
            @extend .tab;

            padding: 0;
            font-weight: 500;
            text-decoration: none;
            background-color: transparent;
            border: unset;

            &--selected {
                @extend .tab--selected;

                color: $highlightGreen;
                font-weight: 600;

                .page-view {
                    &__menu {
                        &-title {
                            padding: 7px 25px;
                        }
                    }
                }
            }
        }
    }

    &__stats {
        margin: 0 7px;
        padding: 5px 0;
        color: $defaultGrayColor;
        font-size: 0.75em;

        &:first-child {
            margin-left: 0;
        }
        
        &:last-child {
            margin-right: 0;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__parents {
        position: absolute;
        top: 202px;
        left: 0;
        z-index: 2;
        direction: rtl;
        transform: rotate(-90deg);
        transform-origin: bottom left;

        .page-view {
            &__menu {
                &-item {
                    &--selected {
                        &:first-child {
                            &::after {
                                right: unset;
                                left: -5px;
                            }

                            &:last-child::after {
                                content: none;
                            }
                        }
                    }

                    &::after {
                        right: -5px;
                        left: unset;
                    }

                    &::before {
                        right: -1px;
                        left: unset;
                    }

                    &:first-child {
                        .page-view {
                            &__menu {
                                &-title {
                                    border-top-left-radius: 0;
                                    border-top-right-radius: 3px;
                                }
                            }
                        }
                    }

                    &:last-child {
                        .page-view {
                            &__menu {
                                &-title {
                                    border-right: 0;
                                    border-left: 1px solid #d3e0ec;
                                    border-top-left-radius: 3px;
                                    border-top-right-radius: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__load-more {
        position: absolute;
        top: 35px;
        left: -9999px;
        padding: 6px 18px;
        cursor: pointer;
        transform: translate(-50%, 0);
        z-index: 2;
        background-color: $defaultBackground;
        border: 1px solid $inputBorder;
        box-shadow: 0px 0px 4px $inputBoxShadow;
        border-radius: 70px;
        font-size: 0.75em;
        opacity: 0;
        transition:
            box-shadow $baseTransitionTime,
            left 0s $baseTransitionTime,
            opacity $baseTransitionTime;

        &::first-letter {
            color: $highlightBlue;
        }

        &:hover {
            box-shadow: 0px 4px 4px $inputBoxShadow;   
        }

        &--active {
            opacity: 1;
            left: 50%;
            transition:
                box-shadow $baseTransitionTime,
                left 0s,
                opacity $baseTransitionTime;
        }
    }

    &__unselect {
        padding: 3px 12px;
        font-size: 0.625rem;
    }
}
