@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../components/CustomTabs/CustomTabs.module.scss';

.page-view {
    padding: 15px 0 0 0;

    @include clearfix;

    &--simple {
        padding: 0;
    }

    &__extra {
        position: absolute;
        right: 0;
        bottom: 100%;
        display: flex;
        align-items: center;
    }

    &__main {
        display: block;
    }

    &__aside {
        position: relative;
        float: right;
        width: 25%;
        height: calc(100vh - 70px);
        min-height: 420px;

        &--disabled {
            opacity: 0.4;
            pointer-events: none;
        }

        & + .page-view {
            &__main {
                margin-right: calc(25% + 30px);
            }
        }

        &--collapsed {
            width: 0;

            & + .page-view {
                &__main {
                    margin-right: 0;

                    .page-view {
                        &__extra {
                            right: 30px;
                        }
                    }
                }
            }
        }
    }
}
