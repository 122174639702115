@import '../../styles/variables';

.copy-to-clipboard {
    &__hint {
        max-width: 200px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);
    
        &::before,
        &::after {
            content: unset !important;
        }
    }

    &__toggle {
        display: inline-block;
        margin: 0 0 0 8px;
        padding: 0;
        vertical-align: middle;
        background-color: transparent;
        border: unset;
        cursor: pointer;

        svg {
            fill: #F2F6F9;
            stroke: $defaultColor;
            transition: stroke $baseTransitionTime;
        }

        &:hover {
            svg {
                fill: white;
                stroke: $highlightBlue;
            }
        }
    }
}