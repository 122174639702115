@import '../../../styles/variables';
@import '../../../styles/mixins';

.directory {
    &-search {
        display: block;

        &--contact {
            :global(.select__indicator-separator) {
                display: none;
            }
        }

        &--client {
            :global(.select__menu) {
                width: 450px;
            }
        }

        &__client {
            &.client {
                padding-top: 4px;
                padding-right: 8px;
                padding-bottom: 4px;
                padding-left: 8px;
                cursor: pointer;
                background-color: transparent;
                transition: background-color $baseTransitionTime;

                @include clearfix;

                &:hover {
                    background-color: $inputBorderHover;
                }

                .client {
                    &__box {
                        float: left;
                        display: flex;

                        &--login {
                            width: 10%;
                        }

                        &--company {
                            width: 30%;
                        }

                        &--address {
                            width: 30%;
                        }

                        &--hardware {
                            width: 30%;
                        }
                    }

                    &__label {
                        width: 10px;
                        text-transform: uppercase;

                        &::after {
                            content: " ";
                        }

                        &--orange {
                            color: $highlightOrange;
                        }

                        &--red {
                            color: $highlightRed;
                        }

                        &--blue {
                            color: $highlightBlue;
                        }

                        &--green {
                            color: $highlightGreen;
                        }
                    }

                    &__value {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding-right: 5px;
                        padding-left: 5px;
                    }
                }
            }
        }
    }

    &-select {
        // HACK: fixes unpredictable flex select width
        :global(.select__value-container) {
            max-width: 100px;
        }

        &__empty {
            :global(.select__value-container) {
                flex: 0;
                padding: 0;
            }
        }

        &__footer {
            margin-top: 8px;
        }

        &__action {
            margin-bottom: 4px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-contact {
        &__notification {
            clear: both;
            margin: 0 60px -30px 0;
            color: $highlightRed;
            font-size: 0.75em;
            text-align: right;
            opacity: 0;
            transition: opacity $baseTransitionTime;

            &--show {
                opacity: 1;
            }
        }

        &__create {
            margin-top: 15px;
            font-size: 1rem;
            text-align: right;
        }

        &__hint {
            max-width: 200px;
            margin-top: -10px !important;
            padding: 3px 6px !important;
            color: $defaultColor !important;
            font-size: 0.625em !important;
            line-height: 1.4;
            background: $defaultBackground !important;
            border-radius: $defaultBorderRadius;
            box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

            &::before,
            &::after {
                content: unset !important;
            }
        }
    }
}

.tag-list-preloader {
    padding: 4px 0;
}

.contacts {
    position: relative;

    &__footer {
        margin-top: 20px;
        white-space: nowrap;

        button {
            padding-right: 10px;
            padding-left: 10px;
        }
    }

    &__td {
        position: relative;
        z-index: 2;
        float: left;
        padding: 0 15px;

        &--head {
            font-weight: 700;
            font-size: 0.75em;
        }

        &--name,
        &--phone {
            width: 33%;
        }

        &--phone {
            :global(.react-autosuggest) {
                &__container {
                    position: relative;
                }

                &__suggestions {
                    &-container {
                        position: absolute;
                        top: 100%;
                        left: -9999px;
                        width: calc(100% - 2px);
                        margin-top: 1px;
                        overflow: auto;
                        background-color: $defaultBackground;
                        border-radius: $defaultBorderRadius;
                        box-shadow: 0 0 4px $inputBoxShadow;

                        &--open {
                            left: 1px;
                        }
                    }

                    &-list {
                        max-height: 210px;
                        margin: 0;
                        padding: 9px 0;
                        font-size: 0.75em;
                        list-style-type: none;
                    }
                }

                &__suggestion {
                    padding-top: 4px;
                    padding-right: 5px;
                    padding-bottom: 4px;
                    padding-left: 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: $inputBorderHover;
                    }
                }
            }
        }

        &--is-agent,
        &--hide {
            width: 10%;
            padding: 0;
            text-align: center;

            & > * {
                display: inline-block;
                margin: 4px 0;
                vertical-align: top;
            }
        }

        &--hide {
            width: 15%;
            padding: 0 0 0 15px;
        }

        &--delete {
            width: 9%;
            padding: 4px 15px 3px 0;
            opacity: 0;
            transition: opacity $baseTransitionTime;
        }
    }

    &__call {
        border: none;
        cursor: pointer;
        margin: -6px 0;
        padding: 6px;
        display: inline-block;
        transition: background-color 0.3s;
        background: transparent;
        border-radius: 50%;

        svg {
            fill: #849cc0;
        }

        &:hover {
            background-color: #d6ebf7;

            svg {
                fill: #3597d4;
            }
        }
    }

    &__call-hint {
        max-width: 200px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

        &::before,
        &::after {
            content: unset !important;
        }
    }

    &__row {
        @include clearfix;

        margin-right: -15px;
        margin-left: -15px;

        &--head {
            margin-bottom: 4px;
        }

        &--body {
            position: relative;
            margin-top: 2px;
            margin-bottom: 2px;
            padding-top: 5px;
            padding-bottom: 5px;

            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 10px;
                z-index: 1;
                width: calc(91% - 15px);
                padding: 5px 0;
                background-color: $defaultBackground;
                border-radius: $defaultBorderRadius;
                transition: background-color $baseTransitionTime;
                content: '';
            }

            &:hover {
                &::before {
                    background-color: $defaultBorderHalfColor;
                }

                .contacts {
                    &__td {
                        &--delete {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__delete {
        background-color: transparent;
        border-color: transparent;
        outline: none;
        cursor: pointer;

        svg {
            fill: $defaultGrayColor;
        }
    }

    &__hint {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 0 0 0 5px;
        color: $defaultBackground;
        font-weight: normal;
        font-size: 0.7em;
        line-height: 12px;
        text-align: center;
        vertical-align: middle;
        background-color: $highlightBlue;
        border-radius: 100%;
    }

    &--readonly {
        .contacts {
            &__td {
                &--name,
                &--phone {
                    width: 46%;
                }
            }

            &__row {
                &--body::before {
                    width: calc(91% - 5px);
                }
            }
        }
    }

    &__error {
        display: inline-block;
        margin-left: 15px;
        color: $error;
        font-size: 0.75em;
    }
}
