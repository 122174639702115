@import '../../../styles/variables';
@import '../../../styles/mixins';

.sidebar {
    display: inline-block;
    width: 24%;
    min-width: 100px;
    height: 100%;
    padding-bottom: 56px;
    overflow-x: auto;
    vertical-align: top;
}

.form {
    height: 100%;
    overflow: hidden;
    opacity: 1;
    transition: opacity $baseTransitionTime;

    &--loading {
        opacity: 0;
    }
}

.field {
    margin: 0 0 16px;

    &-label {
        float: left;
        margin: 0;
        font-size: 0.75em;
        line-height: 2;

        & + * {
            margin-left: 120px;
        }
    }

    @include clearfix;
}

.fieldset {
    display: block;
    margin: 0;
    padding: 20px 12px 20px 20px;
    border: unset;

    &-label {
        position: relative;
        display: block;
        margin-bottom: 24px;
        color: $highlightBlue;
        font-weight: 700;
        font-size: 0.875em;
        line-height: 1.0625em;

        span {
            position: relative;
            z-index: 1;
            padding-right: 10px;
            background-color: #fff;
        }

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            height: 1px;
            background-color: $defaultBorderColor;
            content: '';
        }
    }

    &:last-of-type {
        padding-bottom: 12px;
        border-bottom: unset;
    }

    &--manage,
    &--additionalInfo {
        .fieldset-label {
            margin-bottom: 35px;
        }
    }

    &--manage {
        textarea {
            padding-top: 2px;
            padding-bottom: 2px;
            line-height: 1.25;
        }
    }

    &--location {
        & > .inputlist {
            max-width: 280px;
            margin: 0 auto 16px;

            .field {
                margin: 0;
            }
        }

        & > .field {
            max-width: 280px;
            margin: 0 auto 16px;
        }
    }

    &.warning {
        padding-bottom: 0;
        font-size: 0.75em;
        color: $error;
    }
}

.fieldgroup {
    display: block;
    float: left;
    width: 255px;
    margin-right: 30px;

    .field {
        &-label {
            @extend .field-label;

            padding: 0;
        }
    }

    &-list {
        margin: 0;

        @include clearfix;

        .field {
            float: left;
            width: 100%;

            &--ownerType {
                width: 330px;
            }

            &--onADeposit {
                padding-left: 96px;
            }
        }
    }

    &--contacts {
        float: none;
        width: auto;
        margin-right: 40px;
    }

    &--commentGroup {
        margin-top: -25px;

        .fieldgroup-list {
            .field-label {
                & + * {
                    margin-left: 0;
                }
            }
        }
    }

    &--additionalInfoRight {
        .field--operatorNote {
            & > div {
                margin-left: 0;
            }
        }
    }

    &--operatorNoteWrapper {
        float: none;
        width: auto;

        .fieldgroup-list {
            margin-right: 25px;

            .field-label {
                & + * {
                    margin-left: 0;
                }
            }
        }
    }

    &--basicInfoRight {
        .fieldgroup-list {
            .field-label {
                & + * {
                    margin-left: 120px;
                }
            }

            .field--surcharge {
                .field-label {
                    & + * {
                        margin-left: 95px;
                    }
                }
            }
        }
    }

    &--additionalParams {
        margin: 0;

        .fieldgroup-list {
            margin: 0;
        }

        .field {
            width: auto;
            padding-right: 6px;
        }
    }

    .inputlist {
        display: block;
        float: left;
        width: 100%;
        margin-right: 30px;
        margin-bottom: 16px;

        .field {
            margin-bottom: 0;

            &-label {
                @extend .field-label;
            }
        }

        &--areaSizes,
        &--pricesForSquareArea,
        &--area {
            width: calc(100% + 24px);
            margin-right: -24px;

            .field-label {
                & + * {
                    margin-left: 95px;
                }
            }
        }

        &--area {
            .field-label {
                & + * {
                    margin-left: 120px;
                }
            }
        }
    }

    &--basicInfoInline {
        .field {
            display: inline-block;
            width: auto;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.preloader {
    position: absolute;
    top: 50%;
    left: -9999px;
    z-index: 2;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition:
        left 0s $baseTransitionTime,
        opacity $baseTransitionTime;

    &--loading {
        left: 50%;
        opacity: 1;
        transition:
            left 0s,
            opacity $baseTransitionTime;
    }
}

.content {
    display: inline-block;
    width: 76%;
    height: calc(100% - 40px);
    padding-bottom: 56px;
    vertical-align: top;

    &-title {
        position: sticky;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        margin: 0;
        padding: 8px 10px 7px 16px;
        font-weight: 700;
        font-size: 1.125em;
        line-height: 1.375em;
        background-color: #fff;
        border-bottom: 1px solid $defaultBorderColor;
    }
}

.tooltip {
    float: right;
    width: 1.56em;
    margin-top: 0.75em;
    margin-left: 1em;
    font-size: 0.6875em;
    line-height: 1.4545;
    text-align: center;
    color: $highlightBlue;
    border: 1px solid $defaultBorderColor;
    border-radius: 100%;

    &__content {
        z-index: 1000;
        max-width: 200px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

        :global(.multi-line) {
            text-align: left !important;
        }

        &::before,
        &::after {
            content: unset !important;
        }
    }
}