.svg-icon {
    display: block;
    margin: 0 auto;

    svg {
        display: block;
        fill: inherit;
        stroke: inherit;
    }
}
