@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin clearfix {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

@mixin background-opacity($color, $opacity: 0.3) {
    background-color: $color; /* The Fallback */
    background-color: rgba($color, $opacity);
}
