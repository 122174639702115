@import '../../../styles/variables';

.slider-dialog {
    &__header {
        padding-top: 15px;
        padding-bottom: 5px;
        color: $defaultColor;
        font-weight: 600;
        font-size: 1.125em;
        line-height: 1.2;
    }

    &__body {
        min-height: 530px;
        padding: 10px 30px 18px;
    }
}
