@import '../../styles/variables';

.button {
    position: relative;
    display: inline-block;
    font-weight: 600;
    font-size: 0.75em;
    text-align: center;
    text-decoration: unset;
    background: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: $defaultBorderRadius;
    cursor: pointer;
    transition:
        border-color $baseTransitionTime,
        background $baseTransitionTime,
        color $baseTransitionTime,
        opacity $baseTransitionTime;

    &__content {
        position: relative;
        z-index: 2;
    }

    &--lg {
        padding: 12px 45px;
        font-size: 0.75em;
        line-height: 1.2;
    }

    &--md,
    &--sm {
        font-size: 0.625em;
    }

    &--md {
        padding: 9px 35px;
    }

    &--sm {
        padding: 5px 13px;
    }

    &--transparent {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.2);
        border-color: rgba(255, 255, 255, 0.2);

        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
            border-color: rgba(255, 255, 255, 0.4);
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.2);
            border-color: rgba(255, 255, 255, 0.2);
        }

        .button__icon {
            fill: #fff;
        }
    }

    &--white {
        color: #336797;
        background: #fff;
        border-color: #d3e0ec;

        &:hover {
            background: #fff;
            border-color: #336797;
        }

        &:active {
            background: #f4f8fc;
            border-color: #336797;
        }

        .button__icon {
            fill: #336797;
        }
    }

    &--green {
        color: #fff;
        background: #10bc6a;
        border-color: #10bc6a;

        &:hover {
            background: #07ac5c;
            border-color: #07ac5c;
        }

        &:active {
            background: #03a356;
            border-color: #03a356;
        }

        .button__icon {
            fill: #fff;
        }
    }

    &--blue {
        color: #fff;
        background: #15a1ef;
        border-color: #15a1ef;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(180deg, #1ba0eb 0%, #4fc0ff 100%);
            border-radius: $defaultBorderRadius;
            opacity: 0;
            transition: opacity $baseTransitionTime;
            content: '';
        }

        &:hover {
            border-color: #4fc0ff;

            &::after {
                opacity: 1;
            }
        }

        &:active {
            background: #138fd6;
            border-color: #138fd6;

            &::after {
                opacity: 0;
            }
        }

        .button__icon {
            fill: #fff;
        }
    }

    &--dark-blue {
        color: #fff;
        background: #336797;
        border-color: #336797;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(180deg, #336797 0%, #5795ce 100%);
            border-radius: $defaultBorderRadius;
            opacity: 0;
            transition: opacity $baseTransitionTime;
            content: '';
        }

        &:hover {
            border-color: #336797;

            &::after {
                opacity: 1;
            }
        }

        &:active {
            background: #138fd6;
            border-color: #138fd6;

            &::after {
                opacity: 0;
            }
        }

        .button__icon {
            fill: #fff;
        }
    }

    &--orange {
        color: #fff;
        background: #ff7a00;
        border-color: #ff7a00;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(180deg, #ff7a00 0%, #ff993c 100%);
            border-radius: $defaultBorderRadius;
            opacity: 0;
            transition: opacity $baseTransitionTime;
            content: '';
        }

        &:hover {
            border-color: #ff7a00;

            &::after {
                opacity: 1;
            }
        }

        &:active {
            background: #e6740b;
            border-color: #e6740b;

            &::after {
                opacity: 0;
            }
        }

        .button__icon {
            fill: #fff;
        }
    }

    &--gray {
        color: #fff;
        background: $defaultGrayColor;
        border-color: $defaultGrayColor;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(180deg, $defaultGrayColor 0%, #a6c8e7 100%);
            border-radius: $defaultBorderRadius;
            opacity: 0;
            transition: opacity $baseTransitionTime;
            content: '';
        }

        &:hover {
            border-color: #a6c8e7;

            &::after {
                opacity: 1;
            }
        }

        &:active {
            background: #7e97af;
            border-color: #7e97af;

            &::after {
                opacity: 0;
            }
        }

        .button__icon {
            fill: #fff;
        }
    }

    &--white-red {
        color: #e20d40;
        background-color: #fff;
        border-color: #d3e0ec;

        &:hover {
            border-color: #e20d40;
        }

        &:active {
            background-color: #f4f8fc;
        }

        .button__icon {
            fill: #e20d40;
        }
    }

    &--white-black {
        color: #161c24;
        background-color: #fff;
        border-color: #d3e0ec;

        &:hover {
            border-color: #161c24;
        }

        &:active {
            background-color: #f4f8fc;
        }

        .button__icon {
            fill: #161c24;
        }
    }

    &--white-green {
        color: #10bc6a;
        background-color: #fff;
        border-color: #d3e0ec;

        &:hover {
            border-color: #10bc6a;
        }

        &:active {
            background-color: #f4f8fc;
        }

        .button__icon {
            fill: #10bc6a;
        }
    }

    &--white-blue {
        color: #15a1ef;
        background-color: #fff;
        border-color: #d3e0ec;

        &:hover {
            border-color: #15a1ef;
        }

        &:active {
            background-color: #f4f8fc;
        }

        .button__icon {
            fill: #15a1ef;
        }
    }

    &--white-orange {
        color: #ff7a00;
        background-color: #fff;
        border-color: #d3e0ec;

        &:hover {
            border-color: #ff7a00;
        }

        &:active {
            background-color: #f4f8fc;
        }

        .button__icon {
            fill: #ff7a00;
        }
    }

    &--white-gray {
        color: #d3e0ec;
        background-color: #fff;
        border-color: #d3e0ec;

        &:hover {
            border-color: #d3e0ec;
        }

        &:active {
            background-color: #f4f8fc;
        }

        .button__icon {
            fill: #d3e0ec;
        }
    }

    &--block {
        display: block;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    &:disabled {
        cursor: default;
        opacity: 0.3;
        pointer-events: none;
    }

    &--with-icon {
        &.button {
            &--md,
            &--sm {
                .button {
                    &__content {
                        padding-left: 16px;
                    }
                }
            }

            &--lg {
                .button {
                    &__content {
                        padding-left: 20px;
                    }
                }
            }
        }
    }

    &--collapse {
        position: relative;

        .collapse {
            &__horizontal,
            &__vertical {
                position: absolute;
                display: block;
                transition:
                    opacity $baseTransitionTime,
                    background-color $baseTransitionTime,
                    transform $baseTransitionTime;
            }
        }

        &.button {
            &--sm {
                padding-right: 11px;
                padding-left: 11px;
            }

            &--md {
                padding-right: 15px;
                padding-left: 15px;
            }

            &--lg {
                padding-right: 19px;
                padding-left: 19px;

                .collapse {
                    &__horizontal {
                        top: 50%;
                        left: -8px;
                        width: 16px;
                        height: 2px;
                        margin-top: -1px;
                    }

                    &__vertical {
                        top: 0;
                        left: 0;
                        width: 2px;
                        height: 16px;
                        margin-left: -1px;
                    }
                }
            }

            &--sm,
            &--md {
                .collapse {
                    &__horizontal {
                        top: 50%;
                        left: -6px;
                        width: 12px;
                        height: 2px;
                        margin-top: -1px;
                    }

                    &__vertical {
                        top: 0;
                        left: 0;
                        width: 2px;
                        height: 12px;
                        margin-left: -1px;
                    }
                }
            }

            &--collapsed {
                .collapse {
                    &__vertical {
                        transform: rotate(90deg);
                    }
                }
            }

            &--white {
                .collapse {
                    &__horizontal,
                    &__vertical {
                        background-color: #336797;
                    }
                }
            }

            &--blue,
            &--dark-blue,
            &--orange,
            &--gray,
            &--green {
                .collapse {
                    &__horizontal,
                    &__vertical {
                        background-color: #fff;
                    }
                }
            }

            &--white-red {
                .collapse {
                    &__horizontal,
                    &__vertical {
                        background-color: #e20d40;
                    }
                }
            }

            &--white-black {
                .collapse {
                    &__horizontal,
                    &__vertical {
                        background-color: #161c24;
                    }
                }
            }

            &--white-green {
                .collapse {
                    &__horizontal,
                    &__vertical {
                        background-color: #10bc6a;
                    }
                }
            }

            &--white-blue {
                .collapse {
                    &__horizontal,
                    &__vertical {
                        background-color: #15a1ef;
                    }
                }
            }

            &--white-orange {
                .collapse {
                    &__horizontal,
                    &__vertical {
                        background-color: #ff7a00;
                    }
                }
            }

            &--white-gray {
                .collapse {
                    &__horizontal,
                    &__vertical {
                        background-color: #d3e0ec;
                    }
                }
            }
        }
    }

    &--close {
        margin: 0;
        margin-left: 12px;
        padding: 0;
        vertical-align: middle;
        background-color: transparent;
        border: unset;
        cursor: pointer;

        svg {
            fill: #a2b0bd;
        }
    }

    &--menu {
        display: block;
        width: 100%;
        padding: 5px 15px;
        color: $defaultColor;
        font-weight: 300;
        font-size: 1em;
        line-height: 1.2;
        white-space: nowrap;
        text-align: left;
        background-color: #fff;
        border: unset;
        cursor: pointer;

        span {
            display: inline-block;
            margin-right: 3px;
            vertical-align: middle;
        }

        svg {
            fill: #fff !important;
        }
    }
}
