@import '../../../styles/variables';
@import '../../../styles/mixins';

.filter {
    &--full {
        height: 100%;
    }

    &__collapse {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
    }

    &__content {
        background-color: $defaultBackground;
        border: 1px solid $inputBorder;
        border-bottom: unset;
        border-start-end-radius: $defaultBorderRadius $defaultBorderRadius 0 0;

        &--full {
            height: 100%;
        }
    }

    &__title {
        display: inline-block;
        margin-bottom: -1px;
        padding: 5px 20px;
        color: $highlightGreen;
        font-weight: 600;
        font-size: 0.75em;
        line-height: 1.4;
        background-color: $defaultBackground;
        border: 1px solid $inputBorder;
        border-bottom-color: $defaultBackground;
        border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;

        & + .filter {
            &__content {
                height: calc(100% - 27px);
            }
        }
    }

    &__form {
        position: relative;
        height: 100%;
        overflow: hidden;
    }

    &__label {
        float: left;
        margin: 0;
        font-size: 0.75em;
        line-height: 2;

        & + * {
            margin-left: 120px;

            &.filter {
                &__fieldgroup {
                    &-list {
                        margin-left: 90px;
                    }
                }
            }
        }
    }

    &__fieldset {
        display: block;
        margin: 0;
        padding: 12px 0;
        border: unset;
        border-bottom: 1px solid $inputBorder;

        &:last-of-type {
            padding-bottom: 0;
            border-bottom: unset;
        }

        &--groupBy {
            padding: 4px 0;
        }

        &--comment {
            .filter {
                &__label {
                    float: none;
                    margin-bottom: 5px;
                    line-height: normal;

                    & + * {
                        margin-left: 0;
                    }
                }
            }
        }

        &--userSearch {
            .filter {
                &__label {
                    float: none;
                    margin-bottom: 5px;
                    line-height: normal;

                    & + * {
                        margin-left: 0;
                    }
                }
            }
        }

        &--exclusive {
            padding: 7px 0;
        }
    }

    &__field {
        padding: 4px 0;

        @include clearfix;

        button {
            &:disabled:read-only {
                opacity: 0.3;
            }
        }
    }

    &__fieldgroup {
        display: block;

        .filter {
            &__label {
                @extend .filter__label;

                padding: 4px 0;
            }
        }

        &-list {
            margin: 0 -6px;

            @include clearfix;

            .filter {
                &__field {
                    float: left;
                    width: 50%;
                    padding-right: 6px;
                    padding-left: 6px;
                }

                &__label {
                    & + * {
                        margin-left: 65px;
                    }
                }
            }
        }

        &--floorExclude {
            .filter {
                &__fieldgroup {
                    &-list {
                        .filter {
                            &__field {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }

        &--exclusive {
            .filter {
                &__fieldgroup {
                    &-list {
                        .filter {
                            &__field {
                                width: 100%;
                                margin: 0 -6px;
                            }
                        }
                    }
                }
            }
        }

        &--baseStatusGroups {
            .filter {
                &__fieldgroup {
                    &-list {
                        .filter {
                            &__field {
                                width: 120%;
                                margin: 0 -35px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__field-list {
        height: calc(100% - 121px);
        padding: 8px 16px 16px 12px;
        overflow: auto;
        will-change: transform;
    }

    &__footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        padding: 20px;
        background-color: $defaultBackground;
        border-top: 1px solid $inputBorder;
        box-shadow: 0 0 4px $inputBoxShadow;
    }

    &__submit {
        margin-bottom: 8px;
    }

    &:not(.filter--opened) {
        .filter__content {
            height: 0;
            border: unset;
        }

        .filter__title {
            border-bottom-color: $inputBorder;
            border-radius: $defaultBorderRadius;
        }
    }
}
