@import '../../../styles/variables';
@import '../../../styles/mixins';

.list {
    position: relative;
    max-height: 100%;
    padding: 8px 0;
    padding-bottom: 0;
    border-bottom: 1px solid $inputBorder;
    border-bottom-right-radius: $defaultBorderRadius;
    border-bottom-left-radius: $defaultBorderRadius;

    &__wrapper {
        position: relative;
        min-height: 282px;
        max-height: calc(100vh - 82px);
        padding: 0 12px 0 12px;
        overflow: auto;
    }

    &__preloader {
        position: absolute;
        top: 50%;
        left: -9999px;
        z-index: 2;
        background-color: #fff;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition:
            left 0s $baseTransitionTime,
            opacity $baseTransitionTime;

        &--loading {
            left: 50%;
            opacity: 1;
            transition:
                left 0s,
                opacity $baseTransitionTime;
        }
    }

    &__content {
        padding: 12px 0;
        opacity: 0;
        transition: opacity $baseTransitionTime;

        &--loaded {
            opacity: 1;
        }

        &--loading {
            opacity: 0;
        }
    }

    &__item {
        padding: 4px 0;
    }

    &__empty {
        padding: 34px 0;
        text-align: center;
        font-size: 0.75em;
    }

    &__row {
        &:hover {
            .preset {
                &__check {
                    opacity: 1;
                }
            }
        }
    }

    &__remove {
        color: #000;

        svg {
            fill: #000;
        }
    }

    :global(.react-contextmenu--visible) {
        z-index: 3;
    }
}

.preset {
    &-edit {
        padding: 0 20px 50px 20px;

        &__field {
            margin: 14px 0 24px 0;
            text-align: left;
        }
    }

    &__edit {
        display: inline-block;
        margin-left: 10px;
        color: #a5c4dc;
        vertical-align: middle;
        opacity: 0;
        transition: opacity $baseTransitionTime;

        svg {
            transition: fill $baseTransitionTime, stroke $baseTransitionTime;
            fill: #a5c4dc;
            stroke: #a5c4dc;
        }

        &:hover {
            svg {
                fill: $highlightGreen;
                stroke: $highlightGreen;
            }
        }
    }

    &__title {
        display: inline-block;
        max-width: calc(100% - 55px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
    }

    &__count {
        float: right;
        font-weight: 700;
        color: #000;
    }

    &__trigger {
        position: relative;
        z-index: 1;
        display: block;
        width: calc(100% - 21px);
        padding: 8px 10px;
        font-size: 0.75em;
        font-weight: 500;
        text-align: left;
        background: $defaultBackground;
        border-color: $defaultBorderColor;
        border-style: solid;
        border-width: 1px;
        border-radius: $defaultBorderRadius;
        cursor: pointer;
        transition:
            border $baseTransitionTime,
            color $baseTransitionTime,
            background $baseTransitionTime;

        &:hover {
            background-color: transparentize($highlightGreen, 0.95);
        }

        &--bottom {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            color: $highlightGreen;
        }
    }

    &__check {
        opacity: 0;
        margin: 11px 0;
        padding: 3px;
        float: right;
        color: $highlightBlue;
        border: 1px solid $defaultBorderColor;
        border-radius: $defaultBorderRadius;
        background-color: transparent;
        transition: opacity $baseTransitionTime;
        cursor: pointer;

        svg {
            fill: currentColor;
        }
    }

    &__trigger {
        &:hover {
            z-index: 2;

            .preset {
                &__edit {
                    opacity: 1;
                }
            }
        }
    }

    &--with-new {
        .preset {
            &__trigger {
                &--top {
                    margin-bottom: -1px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    &--active {
        .preset {
            &__trigger {
                border-color: $highlightGreen;
            }
        }
    }

    &:hover {
        .preset {
            &__trigger {
                border-color: $highlightGreen;
            }
        }
    }

    &-remove {
        &__actions {
            display: flex;
            justify-content: space-between;
            margin-top: 33px;
            padding-bottom: 40px;

            button {
                max-width: calc(50% - 11px);
            }
        }

        &__warning {
            font-size: 0.825em;
        }
    }

    &__hint {
        max-width: 200px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

        &::before,
        &::after {
            content: unset !important;
        }
    }
}

.footer {
    font-size: 1rem;
    padding: 18px 12px 0 12px;

    button {
        font-size: 0.75rem;
    }
}