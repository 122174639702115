.item-status {
    display: block;
    width: 350px;
    font-size: 1rem;

    &__field {
        display: block;
        padding: 4px 0;
    }

    &__label {
        display: block;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 0.65em;

        &--radio {
            margin-bottom: 0;
        }
    }

    &__submit {
        display: block;
        margin-top: 12px;
        text-align: right;
    }

    &__tag-list {
        max-height: 90px;
        overflow: auto;
    }

    &__contact-list {
        width: 100%;
        font-size: 0.65em;
        text-align: left;
        margin-bottom: 4px;
        border-spacing: 0;

        th, td {
            padding: 0 12px 0 0;

            &:last-child {
                padding: 0;
            }
        }
    }

    &__contact-form {
        flex: 1;
    }

    &__contact-submit {
        margin-left: 8px;
        margin-bottom: 4px;
    }

    &__contact-container {
        display: flex;
        align-items: flex-end;
    }
}
