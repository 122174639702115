@import '../../../styles/variables';
@import '../../../styles/mixins';

.pagination {
    margin-top: 10px;
}

.filter {
    &__footer {
        position: static;
        z-index: 1;
        padding: 10px 20px 20px 20px;
        background-color: $defaultBackground;
        border-bottom: 1px solid $inputBorder;
    }

    &__field-list {
        position: relative;
        z-index: 2;
        height: auto;
        min-height: calc(100vh - 255px);
        padding: 8px 20px 10px 20px;
        will-change: transform;
    }
}

.directory {
    &-action {
        float: left;
        width: 50%;
        padding: 0 7px;

        &-list {
            margin: 20px -7px 0 -7px;

            @include clearfix;
        }
    }

    &-edit {
        padding: 0 20px 40px 20px;

        fieldset {
            margin: 0;
            padding: 0;
            border: 0;
        }

        &__field {
            padding: 4px 0;

            @include clearfix;
        }

        &__label {
            margin: 0 0 0 0;
            font-size: 0.75em;
            line-height: 2;
            text-align: left;
        }

        &__submit {
            margin-top: 20px;
        }
    }
}
