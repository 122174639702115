@import '../../styles/variables';

.input {
    &--with-icon {
        position: relative;
    }

    &__icon {
        position: absolute;
        top: 50%;
        z-index: 1;
        transform: translate(0, -50%);

        svg {
            fill: $inputBorderFocus;
        }

        &--sm {
            width: 24px;
        }

        &--md {
            width: 32px;
        }

        &--lg {
            width: 40px;
        }
    }
}
