@import '../../styles/variables';

.radius {
  margin-top: -20px;

  &__value {
    font-size: 12px;

    span {
      font-weight: 500;
    }
  }

  &__controls {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    button {
      padding: 9px 25px;
    }
  }

  &__placeholder {
    color: $defaultGrayColor;
    font-size: 12px;
    text-align: center;
  }
}
