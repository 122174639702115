@import '../../styles/mixins';
@import '../../styles/variables';

.radio-list {
    @include clearfix;

    position: relative;

    &__item {
        float: left;
    }

    &--md {
        margin: 0 -4px;

        .radio-list {
            &__item {
                padding: 4px;
            }
        }
    }

    &--sm {
        margin: 0 -2px;

        .radio-list {
            &__item {
                padding: 2px;
            }
        }
    }

    &--lg {
        margin: 0 -4px;

        .radio-list {
            &__item {
                padding: 4px;
            }
        }
    }

    &--error {
        position: relative;
    }

    &--inline {
        .radio-list__item {
            padding: 4px 6px;
            margin-right: 8px;
            border-radius: $defaultBorderRadius;

            &:first-child {
                padding-left: 0;
            }
        }
    }

    &__error {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        color: $error;
        font-size: 0.75em;
    }
}
