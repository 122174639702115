@import '../../../styles/variables';

.filter {
    display: none;
    height: 100%;

    &--opened {
        display: block;
    }

    &-tabs {
        height: 100%;

        &__list {
            position: relative;
            z-index: 1;
            margin-bottom: -1px;
        }

        &__panel {
            display: none;
            height: 100%;

            &--active {
                display: block;
            }
        }

        &__tab {
            text-align: center;

            & > * {
                padding-right: 15px;
                padding-left: 15px;
                border-bottom: 1px solid #d3e0ec;
            }

            &--active {
                &.filter-tabs {
                    &__tab {
                        & > * {
                            padding-right: 15px;
                            padding-left: 15px;
                            color: $highlightGreen;
                            border-bottom-color: #fff;
                        }
                    }
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;

        button {
            white-space: nowrap;
            padding-left: 13px;
            padding-right: 13px;
        }

        &--with-preset-selected {
            button {
                width: calc(50% - 4px);
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 12px 8px 12px;
        border-bottom: 1px solid $defaultBorderColor;
    }
}

.preset-header {
    height: 54px;

    & + form {
        height: calc(100% - 54px);
    }

    &__back {
        white-space: nowrap;

        button {
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    &__text {
        font-size: 0.75em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__name {
        color: $highlightBlue;
    }
}