@import '../../styles/variables';

.sidebar {
    display: inline-block;
    width: 24%;
    min-width: 100px;
    height: 100%;
    overflow-x: auto;
    vertical-align: top;

    &__item {
        position: relative;
        display: block;
        width: 100%;
        padding: 12px 28px 13px 17px;
        overflow: hidden;
        color: #161c24;
        font-weight: 500;
        font-size: 0.875em;
        line-height: 1.0625em;
        white-space: nowrap;
        text-align: left;
        text-decoration: unset;
        text-overflow: ellipsis;
        background-color: #fff;
        border: none;
        border: 1px solid #d3e0ec;
        border-bottom: unset;
        border-left: unset;
        outline: none;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        svg {
            stroke: #161c24;
            fill: #161c24;
            transition: stroke 0.3s, color 0.3s;
        }

        &:hover,
        &--active {
            color: #fff;
            text-decoration: unset;
            background-color: #15a1ef;

            svg {
                stroke: #fff;
                fill: #fff;
            }
        }

        &:first-child {
            border-top: unset;
        }

        &:last-child {
            border-bottom: 1px solid #d3e0ec;
        }

        &-circle {
            position: absolute;
            top: 50%;
            right: 14px;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            transform: translateY(-50%);
        }
    }
}

.content {
    display: inline-block;
    width: 76%;
    height: 100%;
    vertical-align: top;
}

.inner {
    height: 100%;
    margin-left: -1px;
    overflow: hidden;
    overflow-y: auto;
    border-left: 1px solid $defaultBorderColor;
    will-change: transform;
}
