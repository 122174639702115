@import '../../../styles/variables';
@import '../../../styles/mixins';

$selectWidth: 200px;

.table {
    height: 100%;
    overflow: hidden;

    &__type {
        position: relative;
        height: calc(100% + 2px);
        margin: -1px -5px;
        padding: 3px 0;

        &--red {
            svg {
                path {
                    fill: $highlightRed;
                }
            }
        }

        &--black {
            svg {
                path {
                    fill: #000000;
                }
            }
        }

        &--yellow {
            svg {
                path {
                    fill: $highlightYellow;
                }
            }
        }

        &--green {
            svg {
                path {
                    fill: green;
                }
            }
        }

        &--orange {
            svg {
                path {
                    fill: $highlightOrange;
                }
            }
        }

        &--blue {
            svg {
                path {
                    fill: $highlightBlue;
                }
            }
        }
    }

    &__highlight {
        label {
            &::before {
                left: 2px;
            }
        }
    }

    &__hint {
        z-index: 999;
        max-width: 250px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

        &::before,
        &::after {
            content: unset !important;
        }

        &--multiline {
            text-align: left;
        }
    }

    &__row-hint {
        margin-top: 5px;
        padding-top: 3px;
        border-top: 1px solid $defaultBorderColor;
    }

    &__street {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__fias-warning {
        float: left;
        margin-top: 1px;

        svg {
            fill: $highlightOrange;
        }

        & + .table {
            &__street {
                margin-left: 20px;
            }
        }
    }

    &__images {
        padding: 1px 0;
        cursor: pointer;
    }

    &__photo {
        path {
            transition: fill $baseTransitionTime, stroke $baseTransitionTime;
            fill: transparent;
            stroke: $highlightBlue;
        }
    }

    &__slider {
        width: 240px;
        max-width: none;
        margin-top: 5px !important;
        margin-left: 130px !important;
        padding: 7px 10px !important;

        &:global(.show) {
            opacity: 1 !important;
        }

        :global(.BrainhubCarouselItem--active) {
            cursor: pointer;
        }
    }

    &__status {
        z-index: 5 !important;
        max-width: none;
        margin-top: 0 !important;
        padding: 15px 20px !important;
        height: auto;
        overflow: auto;

        &-trigger {
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            &--0 {
                color: $highlightGreen;
            }

            &--1 {
                color: $highlightBlue;
            }

            &--2 {
                color: $highlightOrange;
            }

            &--3 {
                color: $highlightRed;
            }

            &--4 {
                color: $defaultColor;
            }

            &--30 {
                color: $highlightGray;
            }
        }

        &:global(.show) {
            opacity: 1 !important;
        }
    }

    &__similarity {
        max-width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        color: $defaultColor;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--low {
            color: $highlightBlue;
        }

        &--medium {
            color: $highlightOrange;
        }

        &--high {
            color: $highlightRed;
        }
    }

    &__phone {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:global(.link) {
            color: $defaultColor;
        }

        &--similar {
            &:global(.link) {
                color: $highlightBlue;
            }
        }
    }

    &-wrapper {
        position: relative;
        display: block;
        clear: left;
        height: calc(100vh - 73px);
        min-height: 400px;
        border: 1px solid $inputBorder;

        &--loading {
            .table {
                overflow: hidden;
            }
        }
    }

    &__body {
        height: calc(100% - 26px);
    }

    &__list {
        background:
            repeating-linear-gradient(
                180deg,
                $rowOddBackground,
                $rowOddBackground 17px,
                $defaultBackground 17px,
                $defaultBackground 34px
            );

        & > div {
            background:
                repeating-linear-gradient(
                    180deg,
                    $rowOddBackground,
                    $rowOddBackground 17px,
                    $defaultBackground 17px,
                    $defaultBackground 34px
                );
        }
    }

    &__head {
        font-weight: 600;
        background-color: $defaultBackground;
        border-bottom: 1px solid $inputBorder;
    }

    &__row {
        font-size: 0;
        border-bottom: 1px solid $inputBorder;
        cursor: pointer;
        transition: background-color $baseTransitionTime;

        &--grouping {
            display: flex;
            gap: 4px;
            padding-left: 3px;
            background-color: #ddd;

            &:hover {
                background-color: #a4ddcf !important;
            }

            .grouping-row-container {
                display: flex;
                flex-direction: row;
                width: 100%;

                &.container-reverse {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }

                .grouping-label-container {
                    justify-content: center;
                    display: flex;
                    width: 100%;

                    .grouping-label {
                        font-size: 0.69rem;
                        font-weight: 600;
                        display: flex;
                        width: 100%;
                        align-items: center;

                        &--expander {
                            &--rotate-down {
                                transform: rotate(90deg);
                                transition: transform 0.3s ease-in-out;
                            }

                            &--rotate-right {
                                transform: rotate(0deg);
                                transition: transform 0.3s ease-in-out;
                            }
                        }

                        &--title {
                            user-select: none;
                            padding-left: 4px;
                            flex: 1;
                        }
                    }
                }

                .checkbox-right-align {
                    padding-right: 7px;
                }
            }
        }

        &--odd {
            background-color: $defaultBackground;
        }

        &--even {
            background-color: $rowOddBackground;
        }

        &--disabled {
            & > * {
                color: rgba($defaultColor, 0.3);
            }
        }

        &--active {
            background-color: rgba($highlightGreen, 0.58) !important;
            font-weight: 800;

            & > * {
                color: rgba($defaultColor, 1);
            }
        }

        &--selected {
            @include background-opacity($highlightGreen, 0.2);

            & > * {
                color: rgba($defaultColor, 1);
            }
        }

        &:hover {
            background-color: $rowHoverBackground;

            .table {
                &__photo {
                    path:nth-child(1) {
                        fill: $highlightBlue;
                    }

                    path:nth-child(2) {
                        stroke: $defaultBackground;
                    }
                }
            }

            &.table {
                &__row {
                    &--selected,
                    &--active {
                        @include background-opacity($highlightGreen, 0.2);
                    }
                }
            }
        }
    }

    &__title {
        position: relative;
        border-right: 1px solid $inputBorder;

        &--align {
            &-left {
                text-align: left;
            }

            &-center {
                text-align: center;
            }

            &-right {
                text-align: right;
            }
        }

        &:last-child {
            border-right: unset;

            .table__title-dropdown--open {
                & + .table__title-dropdown-content {
                    left: calc(100% - #{$selectWidth});
                }
            }
        }

        &:last-child,
        &:nth-last-child(1),
        &:nth-last-child(2) {
            .table {
                &-header {
                    display: block;

                    &__dropdown {
                        &-content {
                            left: calc(-200px + 100%);
                        }
                    }
                }
            }
        }

        &:hover {
            .table__title {
                &-icon {
                    border-color: $inputBorderHover;
                    opacity: 1;
                }
            }
        }
    }

    &__resizer {
        position: absolute;
        top: 0;
        right: -2px;
        bottom: 0;
        width: 4px;
    }

    &__cell {
        position: relative;
        padding: 0 5px;
        overflow: hidden;
        font-size: 0.69rem;
        line-height: 16px;
        white-space: nowrap;
        text-align: inherit;
        text-overflow: ellipsis;
        border-right: 1px solid $inputBorder;
        transition: color $baseTransitionTime;

        &--align {
            &-left {
                text-align: left;
            }

            &-center {
                text-align: center;
            }

            &-right {
                text-align: right;
            }
        }

        &--object-type {
            overflow: initial;
        }

        &:last-child {
            border-right: unset;
        }

        &-ellipsed {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-brick {
            height: 100%;
            display: flex;
            align-items: center;
        }

        &-source {
             display: flex;
             justify-content: space-between;

            &-expand-icon {
                display: inline;

                :hover {
                    background-color: white;
                    border-radius: 2px;

                    path {
                        filter: brightness(0);
                    }
                }
            }

            &-link {
                overflow-x: hidden;
                text-overflow: ellipsis;
                flex: auto;

                a {
                    text-decoration: none;
                }
            }
        }

        &-price {
            button {
                cursor: pointer;
                border: none;
                padding: 0px;
                display: inline;
                font-size: 10px;
                line-height: 1;
                background: transparent;
                margin-left: 2px;
            }
        }

        &-price-up {
            color: #10bc6a;
        }

        &-price-down {
            color: #e20d40;
        }
    }

    &__preloader {
        position: absolute;
        top: 0;
        left: -9999px;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: rgba($defaultBackground, $alpha: 0.5);
        opacity: 0;
        transition:
            left 0s $baseTransitionTime,
            opacity $baseTransitionTime;

        &--loading {
            left: 0;
            opacity: 1;
            transition:
                left 0s,
                opacity $baseTransitionTime;
        }
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-header {
        position: relative;

        &__text {
            display: block;
            width: 100%;
            padding: 5px;
            overflow: hidden;
            font-weight: 600;
            font-size: 0.75rem;
            white-space: nowrap;
            text-align: inherit;
            text-overflow: ellipsis;

            &--sortable {
                cursor: pointer;
            }
        }

        &__direction {
            margin-left: -3px;
            color: $highlightBlue;
            font-weight: 200;
        }

        &__icon {
            position: absolute;
            right: 5px;
            bottom: 5px;
            width: 16px;
            height: 16px;
            padding: 3px 2px;
            background: #fff;
            border: 1px solid $defaultBorderColor;
            border-radius: 8px;
            opacity: 0;
            transition:
                border-color $baseTransitionTime,
                opacity $baseTransitionTime,
                background-color $baseTransitionTime;

            svg {
                transition: fill $baseTransitionTime;
                fill: $highlightBlue;
            }
        }

        &:hover {
            .table {
                &-header {
                    &__icon {
                        opacity: 1;
                    }
                }
            }
        }

        &__field {
            position: absolute;
            width: $selectWidth;
            margin-top: -5px;
            padding: 15px 10px 10px 10px;
            background: #fff;
            border: 1px solid $defaultBorderColor;
            border-radius: 0 0 $defaultBorderRadius $defaultBorderRadius;
            box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);
            font-size: 1rem;

            :global(.select__control) {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }

            :global(.select__menu) {
                position: relative;
                font-weight: 500;
                border-radius: $defaultBorderRadius;
                box-shadow: unset;
            }

            :global(.select__menu-list) {
                background: $rowOddBackground;

                & > div > div {
                    padding-right: 8px;
                    padding-left: 8px;
                    text-align: left;
                }
            }

            .table {
                &-header {
                    &__input,
                    &__datepicker {
                        margin-bottom: 10px;
                        font-size: 1rem;
                    }

                    &__select {
                        :global(.select__indicators) {
                            width: 20px;
                            padding: 5px;
                        }

                        :global(.select__menu) {
                            max-width: $selectWidth - 20px;
                            min-height: 210px;
                        }

                        :global(.select__option) {
                            padding-right: 8px;
                            padding-left: 8px;
                            text-align: left;
                        }

                        :global(.select__value-container) {
                            max-width: unset;
                        }

                        &-icon {
                            position: absolute;
                            left: 10px;

                            svg {
                                fill: $highlightBlue;
                            }
                        }
                    }

                    &__datepicker {
                        :global(.react-datepicker-popper) {
                            min-width: 240px !important;
                            margin-top: 26px !important;
                            transform: unset !important;
                        }
                    }
                }
            }
        }

        &__button {
            margin-top: 16px;
            padding: 10px 30px;
        }

        &__filter {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        &__dropdown {
            margin: 0;
            padding: 0;
            border: unset;
            border-radius: 0;

            &--open {
                background-color: $rowHoverBackground !important;

                .table-header {
                    &__icon {
                        background-color: $highlightBlue;
                        border-color: $highlightBlue;
                        opacity: 1;

                        svg {
                            fill: #fff;
                        }
                    }
                }
            }
        }

        .menu__content {
            display: block;
        }
    }

    &__price {
        background-color: rgb(158, 245, 244) !important;
    }

    &__dynamic {
        overflow: auto;
        max-height: 250px;

        td {
            padding: 2px 8px;

            &[direction='up'] {
                color: #10bc6a;
            }

            &[direction='down'] {
                color: #e20d40;
            }
        }

        thead td {
            font-weight: 600;
        }
    }
}

.slider {
    :global(.BrainhubCarousel__dots) {
        display: block;
        text-align: center;

        li {
            display: inline-block;
            margin-bottom: 4px !important;
        }
    }
}

.close-sources-tooltip-button {
    cursor: pointer;
    right: 5px;
    position: absolute;
    border: none;
    background: none;
}
