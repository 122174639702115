.ReactModalPortal {
    .ReactModal__Overlay {
        background-color: transparent !important;
        z-index: 10;
    }

    .modal-content {
        border-radius: 4px;
        background: white;
        border: 1px solid rgb(204, 204, 204);
        padding: 10px;
        padding-top: 0px;
        pointer-events: all;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.2);
        overflow: hidden;
      
        .modal-drag-handler {
            height: 36px;
            width: 150%;
            margin-left: -50%;
            position: absolute;
        }
        
        &.react-draggable {
            .modal-drag-handler {
                cursor: grab;
            }
        }
        
        &.react-draggable-dragging {
            .modal-drag-handler {
                cursor: grabbing;
            }
        }
    }
}