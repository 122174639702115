@import '../../styles/mixins';
@import '../../styles/variables';

.textarea {
    opacity: 1;
    transition: opacity $baseTransitionTime;

    &--md {
        textarea {
            padding: 7px 8px;
            font-size: 0.75em;
            line-height: 1.4;
        }
    }

    &--sm {
        textarea {
            padding: 3px 5px;
            font-size: 0.75em;
            line-height: 1.4;
        }
    }

    &--lg {
        textarea {
            padding: 10px 12px;
            font-size: 0.875em;
            line-height: 1.35;
        }
    }

    &--disabled {
        opacity: 0.3;
    }

    &--readonly {
        opacity: 1;
    }

    textarea {
        display: block;
        width: 100%;
        margin: 0;
        background-color: $inputBackground;
        border: 1px solid $inputBorder;
        border-radius: $defaultBorderRadius;
        transition: border-color $baseTransitionTime;
        resize: none;

        @include placeholder {
            color: rgba(0, 0, 0, 0.5);
        }

        &:hover {
            border-color: $inputBorderHover;
        }

        &:focus {
            border-color: $inputBorderFocus;
        }

        &:disabled,
        &:read-only {
            &:hover,
            &:focus {
                border-color: $inputBorder;
            }
        }
    }

    &--error {
        position: relative;
        /* stylelint-disable-next-line no-descending-specificity */
        textarea {
            border-color: $error;

            &:hover {
                border-color: $error;
            }

            &:focus {
                border-color: $error;
            }
        }

        &.textarea {
            &--sm {
                textarea {
                    padding-right: 24px;
                }

                .textarea {
                    &__error {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            &--md {
                textarea {
                    padding-right: 32px;
                }

                .textarea {
                    &__error {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            &--lg {
                textarea {
                    padding-right: 40px;
                }

                .textarea {
                    &__error {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &__error {
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: #fff;
        border-radius: 0 $defaultBorderRadius 0 0;
    }
}
