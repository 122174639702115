@import '../../styles/mixins';
@import '../../styles/variables';

$input-range-primary-color: #10bc6a !default;
$input-range-neutral-color: #eceef0 !default;
$input-range-neutral-light-color: #eee !default;
$input-range-disabled-color: #ccc !default;

// input-range-slider
$input-range-slider-background: $input-range-primary-color !default;
$input-range-slider-height: 12px !default;
$input-range-slider-width: 18px !default;
$input-range-slider-transition:
    top $baseTransitionTime,
    transform $baseTransitionTime;
$input-range-slider-container-transition: left 0.3s ease-out !default;

// input-range-label
$input-range-label-position-bottom: -12px !default;

// input-range-track
$input-range-track-background: $input-range-neutral-light-color !default;
$input-range-track-height: 3px !default;
$input-range-track-transition: left 0.3s ease-out, width 0.3s ease-out !default;
$input-range-track-active-background: $input-range-primary-color !default;
$input-range-track-disabled-background: $input-range-neutral-light-color !default;

.input-range {
    padding-bottom: 10px;
    font-size: 1rem;
    opacity: 1;
    transition: opacity $baseTransitionTime;

    &__value {
        position: relative;
        float: right;
        border: 1px solid $inputBorder;
        border-radius: $defaultBorderRadius;
    }

    &__input {
        display: block;
        color: $defaultColor;
        line-height: 1.2;
        border: none;
        border-radius: $defaultBorderRadius;
        outline: none;
    }

    &__title {
        line-height: 1.715em;
    }

    &__measure {
        position: absolute;
        top: 50%;
        color: $defaultGrayColor;
        transform: translate(0, -55%);
    }

    &__label {
        @include clearfix;

        display: block;
    }

    .range {
        position: relative;
        width: 100%;
        height: $input-range-slider-height;

        &__track {
            position: relative;
            display: block;
            height: $input-range-track-height;
            background: $input-range-track-background;
            border-radius: $defaultBorderRadius;
            cursor: pointer;

            &--background {
                position: absolute;
                top: 50%;
                right: 0;
                left: 0;
                margin-top: -0.5 * $input-range-track-height;
            }

            &--active {
                height: 100%;
                background: $input-range-track-active-background;
                border-radius: $input-range-track-height;
            }
        }

        &__slider {
            position: absolute;
            top: 100%;
            left: ($input-range-slider-width / 2) * -1;
            z-index: 1;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent transparent $input-range-primary-color transparent;
            border-style: solid;
            border-width:
                0
                $input-range-slider-width / 2
                $input-range-slider-width
                $input-range-slider-width / 2;
            border-radius: 3px;
            outline: none;
            transform: scale(0.7, 0.7);
            cursor: pointer;
            transition: $input-range-slider-transition;
            appearance: none;
            will-change: transform;

            &:active,
            &:hover {
                top: calc(100% + 3px);
                transform: scale(1, 1);
            }
        }

        &__label {
            cursor: default;

            &--min,
            &--max {
                position: absolute;
                bottom: $input-range-label-position-bottom;
                z-index: 0;
                color: #a2b0bd;
                font-size: 12px;
                cursor: default;
            }

            &--min {
                left: 0;
            }

            &--max {
                right: 0;
            }

            &--value {
                display: none;
            }
        }
    }

    &--md {
        .input-range {
            &__label {
                margin-bottom: 8px;
                font-size: 0.875em;
            }

            &__input {
                padding: 4px 5px 4px 5px;
                font-size: 1em;

                &--measure {
                    padding-right: 35px;
                }
            }

            &__value {
                font-size: 0.86em;
            }

            &__measure {
                right: 5px;
            }
        }

        .range {
            height: $input-range-slider-height / 1.2;
        }
    }

    &--sm {
        .input-range {
            &__label {
                margin-bottom: 4px;
                font-size: 0.75em;
            }

            &__title {
                line-height: 2;
            }

            &__input {
                padding: 4px 5px 4px 5px;
                font-size: 1em;

                &--measure {
                    padding-right: 35px;
                    font-size: 1em;
                }
            }

            &__value {
                font-size: 1em;
            }

            &__measure {
                right: 5px;
                transform: translate(0, -50%);
            }
        }

        .range {
            height: $input-range-slider-height / 1.5;

            &__slider {
                left: ($input-range-slider-width / 3) * -1;
                border-width:
                    0
                    $input-range-slider-width / 3
                    $input-range-slider-width / 1.5
                    $input-range-slider-width / 3;
            }
        }
    }

    &--lg {
        .input-range {
            &__label {
                margin-bottom: 12px;
                font-size: 1em;
            }

            &__title {
                line-height: 1.75em;
            }

            &__input {
                padding: 5px 8px;
                font-size: 1em;

                &--measure {
                    padding-right: 35px;
                    font-size: 1em;
                }
            }

            &__value {
                font-size: 0.875em;
            }

            &__measure {
                right: 5px;
            }
        }

        .range {
            &__slider {
                left: $input-range-slider-width / 2 * -1;
                border-width:
                    0
                    $input-range-slider-width / 2
                    $input-range-slider-width
                    $input-range-slider-width / 2;
            }
        }
    }

    &--disabled {
        opacity: 0.3;

        .input-range {
            &__input {
                background-color: $defaultBackground;
            }
        }

        .range {
            &__track {
                cursor: default;
            }

            &__slider {
                cursor: default;
                pointer-events: none;
            }
        }
    }

    &--readonly {
        .input-range {
            &__input {
                background-color: $defaultBackground;
                cursor: default;
            }
        }

        .range {
            &__track {
                cursor: default;
            }

            &__slider {
                cursor: default;
                pointer-events: none;
            }
        }
    }
}
