@import '../../styles/variables';

.select {
    display: block;
    font-size: 0.75em;

    &--md {
        :global(.select) {
            &__value-container {
                min-height: 30px;
                padding: 0 8px;
            }

            &__option {
                padding-right: 8px;
                padding-left: 8px;
            }

            &__single-value {
                max-width: calc(100% - 16px);
            }
        }
    }

    &--sm {
        :global(.select) {
            &__value-container {
                min-height: 22px;
                padding: 0 5px;
            }

            &__option {
                padding-right: 5px;
                padding-left: 5px;
            }

            &__single-value {
                max-width: calc(100% - 10px);
            }
        }
    }

    &--lg {
        font-size: 0.875em;

        :global(.select) {
            &__value-container {
                min-height: 38px;
                padding: 0 12px;
            }

            &__option {
                padding-right: 12px;
                padding-left: 12px;
            }

            &__single-value {
                max-width: calc(100% - 14px);
            }
        }
    }

    :global(.select) {
        &__dropdown-indicator {
            width: 0;
            height: 0;
            border-color: $inputBorderFocus transparent transparent transparent;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-radius: 2px;
        }

        &__clear-indicator {
            cursor: pointer;

            svg {
                display: block;
                transition: fill $baseTransitionTime;
                fill: $defaultGrayColor;
            }

            &:hover {
                svg {
                    fill: #15a3ef;
                }
            }
        }

        &__control {
            min-height: 0;
            background-color: $inputBackground;
            border-color: $inputBorder;
            border-radius: $defaultBorderRadius;
            box-shadow: unset;
            transition: border-color $baseTransitionTime;

            &:hover {
                border-color: $inputBorderHover;
                box-shadow: unset;
            }

            &--menu-is-open {
                border-color: $inputBorderFocus;
                box-shadow: unset;

                &:hover {
                    border-color: $inputBorderFocus;
                }

                :global(.select) {
                    &__dropdown-indicator {
                        transform: rotate(180deg);
                    }
                }
            }

            &--is-disabled {
                opacity: 0.3;
                pointer-events: none;
            }
        }

        &__option {
            padding-top: 4px;
            padding-bottom: 4px;

            &:hover,
            &--is-focused {
                background-color: $inputBorderHover;
            }

            &--is-selected {
                color: #fff;
                background-color: $inputBorderFocus;

                &:hover,
                &--is-focused {
                    color: #fff;
                    background-color: $inputBorderFocus;
                }
            }

            &--is-disabled {
                cursor: default;
                opacity: 0.3;
            }
        }

        &__menu {
            z-index: 10;
            margin: 1px 0;
            border-radius: $defaultBorderRadius;
            box-shadow: 0 0 4px $inputBoxShadow;
        }

        &__menu-list {
            max-height: 210px;
            padding: 9px 0;

            &--is-multi {
                :global(.select) {
                    &__option {
                        max-width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;

                        &::before {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            margin-right: 0.5em;
                            vertical-align: middle;
                            background-color: $inputBackground;
                            border: 1px solid $inputBorder;
                            border-radius: $defaultBorderRadius;
                            transition: border-color $baseTransitionTime, background-color $baseTransitionTime;
                            content: '';
                        }

                        &:hover,
                        &--is-focused {
                            background-color: inherit;

                            &::before {
                                border-color: $inputBorderHover;
                            }
                        }

                        &--is-selected {
                            color: inherit;
                            background-color: inherit;

                            &::before {
                                background-color: $checkboxChecked;
                                background-image: url('../../images/checked-icon.svg');
                                background-repeat: no-repeat;
                                background-position: center center;
                                border-color: $checkboxChecked;
                            }

                            &:hover,
                            &--is-focused {
                                color: inherit;
                                background-color: inherit;

                                &::before {
                                    background-color: $checkboxChecked;
                                    border-color: $checkboxChecked;
                                }
                            }
                        }

                        &--is-disabled {
                            cursor: default;
                            opacity: 0.3;
                        }
                    }
                }
            }
        }

        &__multi-value {
            display: block;
            max-width: 90%;
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: transparent;

            & + :global(.select) {
                &__multi-value {
                    &::before {
                        content: ',\00a0';
                    }
                }
            }
        }

        &__placeholder {
            margin: 0;
        }

        &__single-value {
            margin: 0;
            color: $defaultColor;
        }

        &__indicators {
            padding: 0 9px 0 5px;
        }

        &__indicator-separator {
            width: 0;
            margin: 0 8px;
            background-color: transparent;
        }
    }

    &--error {
        /* stylelint-disable-next-line no-descending-specificity */
        :global(.select) {
            /* stylelint-disable-next-line no-descending-specificity */
            &__control {
                border-color: $error;

                &:hover {
                    border-color: $error;
                }

                /* stylelint-disable-next-line no-descending-specificity */
                &--menu-is-open {
                    border-color: $error;

                    &:hover {
                        border-color: $error;
                    }
                }
            }
        }
    }

    &--readonly {
        /* stylelint-disable-next-line no-descending-specificity */
        :global(.select) {
            /* stylelint-disable-next-line no-descending-specificity */
            &__control {
                &--is-disabled {
                    opacity: 1;
                }
            }

            &__indicators {
                display: none;
            }
        }
    }
}

.dropdown-wrapper {
    position: relative;

    .dropdown {
        position: relative;
        z-index: 2;
        font-size: 1rem;
        text-align: left;
        background-color: $inputBackground;
        border-color: $inputBorder;
        border-radius: $defaultBorderRadius;
        box-shadow: unset;
        cursor: default;

        &::after {
            position: absolute;
            top: 50%;
            right: 9px;
            left: auto;
            display: block;
            width: 0;
            height: 0;
            margin-top: -3px;
            margin-left: 0;
            border-color: $inputBorderFocus transparent transparent transparent;
            border-style: solid;
            border-width: 6px 4px 0 4px;
            border-radius: 2px;
            opacity: 1;
            content: '';
        }

        &:hover {
            border-color: $inputBorderHover;
        }

        &__inner {
            z-index: 1;
            display: block;
            width: 100%;
            transition: left 0s, top 0s, opacity 0s;

            :global(.select) {

                &__menu {
                    position: static;
                    overflow: hidden;
                    background-color: $rowOddBackground;
                    border-radius: $defaultBorderRadius;
                    box-shadow: unset;

                    &-list {
                        max-height: 265px;
                    }
                }

                &__indicators {
                    padding: 0;
                }

                &__indicator-separator {
                    display: none;
                }

                &__option {
                    &--is-selected {
                        display: none;
                    }
                }
            }
        }

        &__value {
            width: calc(100% - 20px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &--placeholder {
                opacity: 0.5;
            }
        }

        &__selected {
            max-width: 100%;
            padding-top: 4px;
            padding-bottom: 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;

            &::before {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 0.5em;
                vertical-align: middle;
                background-color: $checkboxChecked;
                background-image: url('../../images/checked-icon.svg');
                background-repeat: no-repeat;
                background-position: center center;
                border: 1px solid $checkboxChecked;
                border-radius: $defaultBorderRadius;
                transition: border-color $baseTransitionTime, background-color $baseTransitionTime;
                content: '';
            }

            &-list {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid $inputBorder;
            }
        }

        &.dropdown {
            &--open {
                z-index: 4;
                background-color: $defaultBackground;
                border-color: $inputBorderFocus;

                &::after {
                    transform: rotate(180deg);
                }

                & + .dropdown {
                    &__inner {
                        top: calc(100% - 1px);
                        right: 0;
                        left: auto;
                        z-index: 3;
                        min-width: 180px;
                        transition: left 0s, top 0s, opacity 0s;
                    }
                }
            }
        }

        &__search {
            &.search {
                .search {
                    &__icon {
                        position: absolute;

                        svg {
                            fill: $highlightBlue;
                        }
                    }
                }
            }
        }

        &--sm {
            padding: 3px 5px;
            font-size: 0.75rem;
            line-height: 1.4;

            & + .dropdown {
                &__inner {
                    padding: 5px;

                    .dropdown {
                        &__search {
                            &.search {
                                .search {
                                    &__icon {
                                        left: 5px;
                                    }
                                }
                            }
                        }

                        &__selected {
                            &-list {
                                margin-right: 8px;
                                margin-left: 8px;
                            }
                        }
                    }

                    :global(.select) {
                        &__value-container {
                            padding-left: 20px;
                        }

                        &__menu {
                            margin-top: 5px;
                            font-size: 1.15em;
                        }

                        &__option {
                            padding-right: 8px;
                            padding-left: 8px;
                        }
                    }
                }
            }
        }

        &--md {
            padding: 7px 8px;
            font-size: 0.75rem;
            line-height: 1.4;

            & + .dropdown {
                &__inner {
                    padding: 8px;

                    .dropdown {
                        &__search {
                            &.search {
                                .search {
                                    &__icon {
                                        left: 8px;
                                    }
                                }
                            }
                        }

                        &__selected {
                            &-list {
                                margin-right: 8px;
                                margin-left: 8px;
                            }
                        }
                    }

                    :global(.select) {
                        &__value-container {
                            padding-left: 28px;
                        }

                        &__menu {
                            margin-top: 8px;
                            font-size: 1.15em;
                        }
                    }
                }
            }
        }

        &--lg {
            padding: 9px 12px;
            font-size: 0.875rem;
            line-height: 1.45;

            & + .dropdown {
                &__inner {
                    padding: 12px;

                    .dropdown {
                        &__search {
                            &.search {
                                .search {
                                    &__icon {
                                        left: 12px;
                                    }
                                }
                            }
                        }

                        &__selected {
                            &-list {
                                margin-right: 12px;
                                margin-left: 12px;
                            }
                        }
                    }

                    :global(.select) {
                        &__value-container {
                            padding-left: 38px;
                        }

                        &__menu {
                            margin-top: 12px;
                            font-size: 1.15em;
                        }
                    }
                }
            }
        }
    }
}
