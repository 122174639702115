@import '../../../styles/variables';

.root {
    // For correct display multiple tabs popup -->
    position: relative;
    min-height: 100vh;
    // For correct display multiple tabs popup -->
    overflow: hidden;
    background-color: $defaultContentBackground;
}
