@import './fonts';
@import './mixins';
@import './variables';
@import './shared';
@import './modules';

body {
    margin: 0;
    padding: 0;
    color: $defaultColor;
    font-size: $defaultFontSize;
    font-family: $defaultFontFamily, sans-serif;
    background-color: #fff;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

fieldset {
    margin: 0;
    padding: 0;
    border: unset;
    min-inline-size: auto;
}

button,
input,
textarea {
    color: $defaultColor;
    font-family: $defaultFontFamily, sans-serif;
    outline: none;
}

input {
    @include placeholder {
        font-weight: 500;
        font-family: $defaultFontFamily, sans-serif;
    }
}

label {
    margin: 0;
}

hr {
    border-color: #ccc;
    border-style: solid;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}

.hidden {
    display: none;
}

.clearfix {
    @include clearfix;
}

.handle {
    cursor: grab;

    &:active,
    &:focus {
        cursor: grabbing;
    }
}
