@import '../../../styles/variables';

@import '../../../styles/mixins';

.wrapper {
    position: absolute;
    top: 0;
    left: -9999px;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #fff;

    &--loading {
        left: 0;
    }
}

.preloader {
    display: block;
    width: 66px;
    margin: 0 auto;
   
    @include clearfix;

    > div {
        float: left;
        width: 50%;
        padding: 2px;

        &::after {
            display: block;
            padding: 50% 0;
            content: '';
            background-color: $highlightGreen;
            border-radius: $defaultBorderRadius;
            opacity: 0.1;
            animation: preloader 2s linear infinite;
        }
        
        &:nth-child(1) {
            &::after {
                animation-delay: -0.5s;

            }
        }
        
        &:nth-child(2) {
            &::after {
                animation-delay: 0s;

            }
        }
        
        &:nth-child(4) {
            &::after {
                animation-delay: -1.5s;

            }
        }

        &:nth-child(3) {
            &::after {
                animation-delay: -1s;

            }
        }
    }
}

.fingerprint {
    &__title {
        font-size: 0.75em;
        text-transform: none;

        &--lg {
            padding-top: 40px;
            font-size: 1em;
        }
    }

    &__description {
        margin-right: 40px;
        margin-bottom: 65px;
        margin-left: 40px;
        font-size: 0.75em;
        font-weight: 700;
        text-align: center;

        &--line {
            position: relative;
            margin-bottom: 12px;

            &::before {
                position: absolute;
                top: -15px;
                left: 50%;
                width: 55px;
                height: 3px;
                background-color: $defaultBorderColor;
                transform: translateX(-50%);
                content: '';
            }
        }
    }

    &__left {
        margin: 65px -25px -30px -25px;
        padding: 0.5em;
        font-size: 0.875em;
        font-weight: 500;
        text-align: center;;
        background-color: $defaultBorderColor;
        border-radius: $defaultBorderRadius;

        b {
            font-size: 1.25em;
        }
    }

    &__phone {
        text-align: center;
        font-size: 1.125em;
        font-weight: 700;

        a {
            color: $highlightBlue;
            text-decoration: none;
        }
    }

    &__warning {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1em;
        margin: 75px 50px -45px 50px;
        padding: 0.715em 1em;
        font-size: 0.875em;
        font-weight: 600;
        border: 2px solid $highlightOrange;
        border-radius: $defaultBorderRadius;
    }

    .preloader {
        margin-top: 65px;
        margin-bottom: 10px;

        &--top {
            margin-top: 40px;
        }
    }
}

@keyframes preloader {
    100% {
        opacity: 0.1;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 0.8;
    }

    75% {
        opacity: 0.4;
    }
}