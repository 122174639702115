.stats {
  display: flex;
  min-height: calc(100vh - 160px);
  text-align: left;
  padding-bottom: 24px;

  &__chart {
    width: 800px;
    height: 500px;
    margin-right: 24px;
  }

  &__empty {
    color: #a2b0bd;
    padding: 16px;
    text-align: center;
  }

  &__footer {
    &, button {
      margin-top: 8px;
    }
  }

  &__loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__filters {
    width: 320px;
    border: 1px solid #d3e0ec;
    display: flex;
    padding: 16px;
    overflow: auto;
    padding-top: 0;
    max-height: calc(100vh - 100px - 89px - 32px);
    flex-direction: column;
    justify-content: space-between;
  }

  &__tooltip {
    color: #15a1ef;
    border: 1px solid #d3e0ec;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 500;
    background: white;
    border-radius: 4px;
  }

  @media (max-height: 680px) {
    &__chart {
      height: 400px;
    }
  }
}