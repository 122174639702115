@import '../../../styles/variables';
@import '../../../styles/mixins';

$modalTransitionTime: 0.7s;

.content {
    position: relative;
    z-index: 5;
    display: inline-block;
    min-width: 290px;
    max-width: 570px;
    margin: 0;
    background-color: #fff;
    border-radius: $defaultBorderRadius;
    box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);
    opacity: 0;
    pointer-events: none;
    will-change: transform;

    &__inner {
        position: relative;
        z-index: 1; 
        &--auto {
            min-height: auto;
        }

        &--sm {
            min-height: 200px;
        }
    }
}

.dialog {
    position: fixed;
    top: 0;
    left: -99999px;
    z-index: 5;
    display: block;
    width: 100%;
    height: 100%;
    overflow: none;
    text-align: center;
    background-color: rgba(46, 62, 71, 0);
    transition:
        opacity $baseTransitionTime,
        background-color $baseTransitionTime,
        left 0s $modalTransitionTime;
    pointer-events: none;

    &__header {
        position: relative;
        z-index: 4;
        padding: 10px;
        padding-top: 45px;
        text-align: center;
    }

    &__title {
        margin: 0;
        padding: 0;
        color: $defaultColor;
        font-weight: 500;
        font-size: 1.5em;
        line-height: 1.2;
    }

    &__body {
        position: relative;
        z-index: 2;
        padding: 10px 40px;
    }

    &__footer {
        position: relative;
        z-index: 5;
        box-sizing: border-box;
        width: 100%;
        padding: 25px 10px;

        &--default {
            padding-top: 55px;
        }
    }

    &--open {
        left: 0;
        z-index: 400;
        overflow: auto;
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 1;
        transition:
            opacity $baseTransitionTime,
            background-color $baseTransitionTime;
        pointer-events: auto;

        .content {
            top: calc(50% - 100px);
            margin: 100px 0;
            animation-name: anim-open;
            animation-duration: $modalTransitionTime;
            animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
            animation-fill-mode: forwards;
            pointer-events: auto;
        }
    }

    &--close {
        opacity: 1;
        transition:
            opacity $baseTransitionTime,
            background-color $baseTransitionTime,
            left 0s $modalTransitionTime;

        .content {
            animation-name: anim-close;
            animation-duration: $modalTransitionTime;
            animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
            animation-fill-mode: forwards;
            pointer-events: auto;
        }
    }

    &--sm {
        .content {
            width: 380px;
            min-width: 380px;
        }
    }

    &--md {
        .content {
            width: 465px;
            min-width: 465px;
        }
    }

    &--lg {
        .content {
            width: 854px;
            min-width: 854px;
        }
    }

    &--xlg {
        .content {
            width: 1224px;
            min-width: 1224px;
        }
    }
}

:global(#dialog-mortgage) {
    display: block;

    .content {
        width: 530px;
    }
}

.approve {
    display: block;

    &__footer {
        padding: 32px 60px ;
        margin: 0 -11px;

        @include clearfix;
    }

    &__button {
        float: left;
        width: 50%;
        padding: 0 11px;
    }

    &__message {
        padding: 0 20px;
        font-size: 0.75em;
    }
}

.close {
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 10;
    font-size: 0;
    border: unset;
    outline: none;
    cursor: pointer;
    opacity: 1;
    transition: all $baseTransitionTime;

    &:hover {
        color: #009fff;
    }

    &:active {
        color: #4d4d4d;
    }
}

.footer {
    &__button {
        min-width: 309px;
    }
}

:global {
    .dialog-overlay {
        overflow: hidden;
    }
}

@keyframes anim-open {
    0% {
        transform: translate(0, -800px);
        opacity: 0;
    }

    100% {
        transform: translate(0, calc(-50% + 0.5px));
        opacity: 1;
    }
}

@keyframes anim-close {
    0% {
        transform: translate(0, calc(-50% + 0.5px));
        opacity: 1;
    }

    100% {
        transform: translate(0, 100px);
        opacity: 0;
    }
}

@keyframes anim-elem {
    0% {
        transform: translate(0, -100px);
        opacity: 0;
    }

    100% {
        transform: translate(0, calc(-50% + 0.5px));
        opacity: 1;
    }
}
