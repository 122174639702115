/* stylelint-disable no-descending-specificity */

@import '../../styles/mixins';
@import '../../styles/variables';

.input {
    opacity: 1;
    transition: opacity $baseTransitionTime;

    &--md {
        input {
            padding: 7px 8px;
            font-size: 0.75em;
            line-height: 1.4;
        }
    }

    &--sm {
        input {
            padding: 3px 5px;
            font-size: 0.75em;
            line-height: 1.4;
        }
    }

    &--lg {
        input {
            padding: 10px 12px;
            font-size: 0.875em;
            line-height: 1.35;
        }
    }

    &--disabled {
        opacity: 0.3;
    }

    &--readonly {
        opacity: 1;
    }

    input {
        display: block;
        width: 100%;
        margin: 0;
        background-color: $inputBackground;
        border: 1px solid $inputBorder;
        border-radius: $defaultBorderRadius;
        transition: border-color $baseTransitionTime;

        @include placeholder {
            color: rgba(0, 0, 0, 0.5);
        }

        &:hover {
            border-color: $inputBorderHover;
        }

        &:focus {
            border-color: $inputBorderFocus;
        }

        &:disabled,
        &:read-only {
            pointer-events: none;

            &:hover,
            &:focus {
                border-color: $inputBorder;
            }
        }
    }

    &--error {
        input {
            border-color: $error;

            &:hover {
                border-color: $error;
            }

            &:focus {
                border-color: $error;
            }
        }

        &.input {
            &--sm {
                input {
                    padding-right: 24px;
                }

                .input {
                    &__error {
                        width: 24px;
                    }
                }
            }

            &--md {
                input {
                    padding-right: 32px;
                }

                .input {
                    &__error {
                        width: 32px;
                    }
                }
            }

            &--lg {
                input {
                    padding-right: 40px;
                }

                .input {
                    &__error {
                        width: 40px;
                    }
                }
            }
        }

        .input {
            &__field {
                position: relative;
            }
        }
    }

    &__copy {
        position: absolute;
        top: 1px;
        right: 0;
        bottom: 1px;
        padding: 0 10px;
        background-color: transparent;
        border: unset;
        border-radius: 100%;
        outline: none;
        cursor: pointer;
        transition: background-color $baseTransitionTime;

        svg {
            transition: stroke $baseTransitionTime;
            fill: transparent;
            stroke: $inputButtonColor;
        }

        &:hover {
            background-color: $inputButtonBackgroundHover;

            svg {
                stroke: $inputButtonColorHover;
            }
        }

        &:active {
            background-color: transparent;

            svg {
                stroke: $inputButtonColorHover;
            }
        }
    }

    &__error {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }

    &--with-icon {
        &.input {
            &--sm {
                input {
                    padding-left: 24px;
                }
            }

            &--md {
                input {
                    padding-left: 32px;
                }
            }

            &--lg {
                input {
                    padding-left: 40px;
                }
            }
        }

        .input {
            &__field {
                position: relative;
            }
        }
    }

    &--copy {
        &.input {
            &--sm {
                input {
                    padding-right: 24px;
                }

                .input {
                    &__copy {
                        padding: 0 3px;
                    }
                }
            }

            &--md {
                input {
                    padding-right: 32px;
                }

                .input {
                    &__copy {
                        padding: 0 7px;
                    }
                }
            }

            &--lg {
                input {
                    padding-right: 40px;
                }

                .input {
                    &__copy {
                        padding: 0 8px;
                    }
                }

                .copy {
                    &__icon {
                        transform: translateX(12%);
                    }
                }
            }
        }

        &.input--error {
            &.input {
                &--sm {
                    input {
                        padding-right: 50px;
                    }

                    .input {
                        &__copy {
                            right: 24px;
                        }
                    }
                }

                &--md {
                    input {
                        padding-right: 56px;
                    }

                    .input {
                        &__copy {
                            right: 28px;
                        }
                    }
                }

                &--lg {
                    input {
                        padding-right: 70px;
                    }

                    .input {
                        &__copy {
                            right: 36px;
                        }
                    }
                }
            }
        }

        .input {
            &__field {
                position: relative;
            }
        }
    }

    &--prefix,
    &--postfix {
        &.input {
            &--md {
                .input {
                    &__prefix,
                    &__postfix {
                        padding-top: 8px;
                        padding-bottom: 8px;
                        font-size: 0.75em;
                        line-height: 1.4;
                    }
                }
            }

            &--sm {
                .input {
                    &__prefix,
                    &__postfix {
                        padding-top: 4px;
                        padding-bottom: 4px;
                        font-size: 0.75em;
                        line-height: 1.4;
                    }
                }
            }

            &--lg {
                .input {
                    &__prefix,
                    &__postfix {
                        padding-top: 11px;
                        padding-bottom: 11px;
                        font-size: 0.875em;
                        line-height: 1.35;
                    }
                }
            }
        }
    }

    &--prefix {
        .input {
            &__field {
                margin-left: 24px;
            }
        }
    }

    &--postfix {
        .input {
            &__field {
                margin-right: 24px;
            }
        }
    }

    &__field {
        display: block;
    }

    &__postfix,
    &__prefix {
        width: 24px;
        color: $defaultGrayColor;
        white-space: nowrap;
    }

    &__postfix {
        float: right;
        padding-left: 4px;
        text-align: left;
    }

    &__prefix {
        float: left;
        padding-right: 7px;
        text-align: right;
    }
}

.copy {
    &__hint {
        max-width: 200px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

        &::before,
        &::after {
            content: unset !important;
        }
    }
}