@import '../../../styles/variables';
@import '../../../styles/mixins';

.list {
    position: relative;
    max-height: 100%;
    padding: 8px 0 82px 0;
    border-bottom: 1px solid $inputBorder;

    &__wrapper {
        position: relative;
        min-height: 200px;
        max-height: calc(100vh - 164px);
        padding: 0 16px 0 12px;
        overflow: auto;
    }

    &__preloader {
        position: absolute;
        top: 50%;
        left: -9999px;
        z-index: 2;
        background-color: #fff;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition:
            left 0s $baseTransitionTime,
            opacity $baseTransitionTime;

        &--loading {
            left: 50%;
            opacity: 1;
            transition:
                left 0s,
                opacity $baseTransitionTime;
        }
    }

    &__content {
        padding: 12px 0;
        opacity: 0;
        transition: opacity $baseTransitionTime;

        &--loaded {
            opacity: 1;
        }

        &--loading {
            opacity: 0;
        }
    }

    &__item {
        padding: 4px 0;
    }

    &__edit {
        display: inline-block;
        margin-left: 10px;
        color: #a5c4dc;
        vertical-align: middle;
        opacity: 0;
        transition: opacity $baseTransitionTime;

        svg {
            transition: fill $baseTransitionTime, stroke $baseTransitionTime;
            fill: #a5c4dc;
            stroke: #a5c4dc;
        }

        &:hover {
            svg {
                fill: $defaultColor;
                stroke: $defaultColor;
            }
        }
    }

    &__title {
        display: inline-block;
        max-width: calc(100% - 55px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
    }

    &__count {
        float: right;
        font-weight: 700;
    }

    &__trigger,
    &__create {
        display: block;
        width: 100%;
        padding: 16px 18px;
        font-size: 0.75em;
        text-align: left;
        background: $defaultBackground;
        border-color: $defaultBorderColor;
        border-style: solid;
        border-width: 1px;
        border-radius: $defaultBorderRadius;
        cursor: pointer;
        transition:
            border $baseTransitionTime,
            color $baseTransitionTime,
            background $baseTransitionTime;

        &:hover,
        &:active {
            background: $rowOddBackground;
            border-color: #336797;
        }
    }

    &__trigger {
        &:hover {
            .list {
                &__edit {
                    opacity: 1;
                }
            }
        }
    }

    &__create {
        position: absolute;
        right: 16px;
        bottom: 16px;
        left: 12px;
        width: calc(100% - 28px);
        color: #336797;
        text-align: center;
    }

    &__empty {
        padding: 34px 0;
        text-align: center;
        font-size: 0.75em;
    }
}

.notepad {
    &-edit {
        padding: 0 20px 50px 20px;

        &__field {
            margin: 14px 0 24px 0;
            text-align: left;
        }
    }
}

.hint {
    z-index: 1;
    width: 140px;
    margin-top: -10px !important;
    padding: 3px 6px !important;
    color: $defaultColor !important;
    font-size: 0.625em !important;
    line-height: 1.4;
    background: $defaultBackground !important;
    border-radius: $defaultBorderRadius;
    box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

    &::before,
    &::after {
        content: unset !important;
    }
}
