@font-face {
    font-weight: normal;
    font-family: 'golos';
    font-style: normal;
    src: url('../fonts/Golos\ Text_Regular/Golos\ Text_Regular.eot');
    src:
        url('../fonts/Golos\ Text_Regular/Golos\ Text_Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Golos\ Text_Regular/Golos\ Text_Regular.woff2') format('woff2'),
        url('../fonts/Golos\ Text_Regular/Golos\ Text_Regular.woff') format('woff'),
        url('../fonts/Golos\ Text_Regular/Golos\ Text_Regular.ttf') format('truetype');
}

@font-face {
    font-weight: 700;
    font-family: 'golos';
    font-style: normal;
    src: url('../fonts/Golos\ Text_Bold/Golos\ Text_Bold.eot');
    src:
        url('../fonts/Golos\ Text_Bold/Golos\ Text_Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Golos\ Text_Bold/Golos\ Text_Bold.woff2') format('woff2'),
        url('../fonts/Golos\ Text_Bold/Golos\ Text_Bold.woff') format('woff'),
        url('../fonts/Golos\ Text_Bold/Golos\ Text_Bold.ttf') format('truetype');
}

@font-face {
    font-weight: 600;
    font-family: 'golos';
    font-style: normal;
    src: url('../fonts/Golos\ Text_DemiBold/Golos\ Text_DemiBold.eot');
    src:
        url('../fonts/Golos\ Text_DemiBold/Golos\ Text_DemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Golos\ Text_DemiBold/Golos\ Text_DemiBold.woff2') format('woff2'),
        url('../fonts/Golos\ Text_DemiBold/Golos\ Text_DemiBold.woff') format('woff'),
        url('../fonts/Golos\ Text_DemiBold/Golos\ Text_DemiBold.ttf') format('truetype');
}

@font-face {
    font-weight: 500;
    font-family: 'golos';
    font-style: normal;
    src: url('../fonts/Golos\ Text_Medium/Golos\ Text_Medium.eot');
    src:
        url('../fonts/Golos\ Text_Medium/Golos\ Text_Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Golos\ Text_Medium/Golos\ Text_Medium.woff2') format('woff2'),
        url('../fonts/Golos\ Text_Medium/Golos\ Text_Medium.woff') format('woff'),
        url('../fonts/Golos\ Text_Medium/Golos\ Text_Medium.ttf') format('truetype');
}
