@import '../../../styles/variables';
@import '../../../styles/mixins';

.social-network {
  &__toggle {
    display: flex;
    padding: 3px 5px;
    font-size: 1.1em;
    line-height: 1.1;
    align-items: center;
    border-color: transparent !important;
    background-color: transparent !important;

    &:after {
      margin-top: 6px;
    }

    &+div {
      margin-top: 6px;
    }
  }

  &__action {
    font-size: 1.1em;
    line-height: 1.1;

    &:hover {
      background: #f4f8fc;
    }
  }

  &__menu {
    padding: 4px 0;
  }

  &__icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }

  &__context {
    svg {
      fill: #15A1EF !important;
      width: 14px;
      height: 14px;
    }
  }
}

.social-network-dialog {
    &__header {
        padding-right: 60px;
        padding-left: 60px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
    }

    &__input {
      margin-bottom: 16px;
    }

    &__info {
      color: #a2b0bd;
      font-size: 14px;
      text-align: right;
      margin-bottom: 4px;

      &--error {
        color: $error;
      }
    }

    &__images {
      display: flex;
      flex-wrap: wrap;

      &>div {
        float: none;
        min-height: 140px;
      }
    }

    &__footer {
      display: flex;
      padding: 0 40px 10px 40px;
      justify-content: flex-end;

      button {
        margin-left: 8px;
      }

      circle {
        stroke: #fff;
      }
    }

    &__container {
      overflow: auto;
      max-height: calc(100vh - 200px);
    }

    &__warning {
      color: $error;
      font-size: 18px;
    }
}