@import '../../styles/variables';

.radio {
    position: relative;
    display: block;

    &__label {
        position: relative;
        display: block;
        line-height: 1.35;
        cursor: pointer;
        opacity: 1;
        transition: opacity $baseTransitionTime;

        &::before,
        &::after {
            position: absolute;
            border-radius: 100%;
            content: '';
        }

        &::before {
            top: 0;
            left: 0;
            background-color: $inputBackground;
            border: 1px solid $inputBorder;
            transition: border-color $baseTransitionTime;
        }

        &::after {
            background-color: $inputBorderFocus;
            opacity: 0;
            transition: opacity $baseTransitionTime;
        }

        &:hover {
            &::before {
                border-color: $inputBorderHover;
            }
        }
    }

    &--sm {
        .radio {
            &__label {
                padding-left: 12px;
                font-size: 0.625em;

                &::before {
                    width: 12px;
                    height: 12px;
                }

                &::after {
                    top: 2px;
                    left: 2px;
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }

    &--md {
        .radio {
            &__label {
                padding-left: 16px;
                font-size: 0.75em;

                &::before {
                    width: 16px;
                    height: 16px;
                }

                &::after {
                    top: 3px;
                    left: 3px;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    &--lg {
        .radio {
            &__label {
                padding-left: 16px;
                font-size: 0.875em;

                &::before {
                    top: 1px;
                    width: 16px;
                    height: 16px;
                }

                &::after {
                    top: 4px;
                    left: 3px;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    &--children {
        &.radio {
            &--sm {
                .radio {
                    &__label {
                        padding-left: 18px;
                    }
                }
            }

            &--md {
                .radio {
                    &__label {
                        padding-left: 24px;
                    }
                }
            }

            &--lg {
                .radio {
                    &__label {
                        padding-left: 24px;
                    }
                }
            }
        }
    }

    &__input {
        position: absolute;
        left: -9999px;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;

        &:checked,
        &--checked {
            & + .radio {
                &__label {
                    &::before {
                        border-color: $inputBorderHover;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        &:disabled {
            & + .radio {
                &__label {
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }

        &--readonly {
            & + .radio {
                &__label {
                    pointer-events: none;
                    opacity: 1 !important;
                }
            }
        }
    }
}
