@import '../../styles/variables';

.breadcrumbs {
    display: inline-block;
    padding: 6px 0;
    font-size: 0.625em;

    &__item {
        position: relative;
        color: $defaultGrayColor;
        text-decoration: none;

        &::after {
            display: inline-block;
            margin: 0 10px;
            content: url('../../images/arrow-right-icon.svg');
        }

        &--active {
            pointer-events: none;

            &::after {
                content: unset;
            }
        }

        &:disabled {
            pointer-events: none;
        }
    }
}
