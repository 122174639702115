@import '../../styles/mixins';
@import '../../styles/variables';

.checkbox-list {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
        content: unset;
    }

    &__item {
        padding: 5px 8px;
        border-radius: $defaultBorderRadius;
    }

    &--md {
        margin: 0 0 0 -8px;
        gap: 4px;

        .checkbox-list {
            &__item {
                padding: 5px 8px;
            }

            &__checkbox {
                &.checkbox-list {
                    &__checkbox {
                        &--md {
                            label {
                                padding-left: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    &--sm {
        margin: 0 -6px;

        .checkbox-list {
            &__item {
                padding: 2px 6px;
            }
        }
    }

    &--lg {
        margin: 0 -10px;

        .checkbox-list {
            &__item {
                padding: 6px 10px;
            }
        }
    }

    &--error {
        position: relative;
    }

    &--colored {
        .checkbox-list__item {
            &:nth-child(1) {
                background-color: #faf0f6;
            }
    
            &:nth-child(2) {
                background-color: #f3f0fa;
            }
        }
    }

    &__error {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        color: $error;
        font-size: 0.75em;
    }
}
