@import '../../styles/mixins';
@import '../../styles/variables';

.companies {
    @include clearfix;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 150px;

    &__list {
        min-width: 250px;
        max-height: 262px;
        margin-right: 22px;
        padding: 8px 9px;
        background-color: $rowOddBackground;
        border-radius: $defaultBorderRadius;
        overflow: auto;

        hr {
            border-color: $defaultBorderColor;
        }
    }

    &__item {
        margin: 8px 0;
    }

    &__checkbox {
        label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__warning {
        margin-top: 22px;
        font-size: 0.75em;
    }

    &__action {
        margin-bottom: 8px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}