@import '../../styles/variables';

.error {
    position: relative;
    display: block;

    svg {
        fill: $error;
    }

    &__message {
        min-width: 200px;
        padding: 3px 10px !important;
        font-size: 0.75em !important;
        line-height: 1.4;
        background-color: $error !important;

        &::before,
        &::after {
            content: unset !important;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
