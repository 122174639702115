@import '../../styles/mixins';
@import '../../styles/variables';

.input-list {
    display: block;

    &__list {
        display: block;
    }

    &__row {
        @include clearfix;
    }

    &__item {
        position: relative;
        float: left;

        &::after {
            position: absolute;
            top: 50%;
            left: 100%;
            color: $defaultGrayColor;
            font-size: 0.75em;
            line-height: 1;
            transform: translate(-40%, -50%);
            content: '–';
        }

        &:last-child {
            &::after {
                content: unset;
            }
        }
    }

    &--md {
        .input-list {
            &__row {
                margin: 0 -7px;
            }

            &__item {
                padding: 0 7px;
            }
        }
    }

    &--sm {
        .input-list {
            &__row {
                margin: 0 -5px;
            }

            &__item {
                padding: 0 5px;
            }
        }
    }

    &--lg {
        .input-list {
            &__row {
                margin: 0 -10px;
            }

            &__item {
                padding: 0 10px;
            }
        }
    }

    &--prefix {
        .input-list {
            &__list {
                margin-left: 25px;
            }
        }
    }

    &--postfix {
        .input-list {
            &__list {
                margin-right: 25px;
            }
        }
    }

    &__postfix,
    &__prefix {
        width: 24px;
        color: $defaultGrayColor;
        white-space: nowrap;
    }

    &__postfix {
        float: right;
        margin-left: -4px;
        padding-left: 4px;
        text-align: left;
    }

    &__prefix {
        float: left;
        padding-right: 7px;
        text-align: right;
    }

    &--prefix,
    &--postfix {
        &.input-list {
            &--md {
                .input-list {
                    &__prefix,
                    &__postfix {
                        padding-top: 8px;
                        padding-bottom: 8px;
                        font-size: 0.75em;
                        line-height: 1.4;
                    }
                }
            }

            &--sm {
                .input-list {
                    &__prefix,
                    &__postfix {
                        padding-top: 4px;
                        padding-bottom: 4px;
                        font-size: 0.75em;
                        line-height: 1.4;
                    }
                }
            }

            &--lg {
                .input-list {
                    &__prefix,
                    &__postfix {
                        padding-top: 11px;
                        padding-bottom: 11px;
                        font-size: 0.875em;
                        line-height: 1.35;
                    }
                }
            }
        }
    }
}
