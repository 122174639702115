@import '../../../styles/variables';
@import '../../../styles/mixins';

.button-close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.content {
    display: inline-block;
    width: 76%;
    height: calc(100% - 40px);
    padding-bottom: 56px;
    vertical-align: top;

    &-title {
        position: sticky;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        margin: 0;
        padding: 8px 10px 7px 16px;
        font-weight: 700;
        font-size: 1.125em;
        line-height: 1.375em;
        background-color: #fff;
        border-bottom: 1px solid $defaultBorderColor;
    }
}

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 12px 12px 12px 18px;
    white-space: nowrap;
    background-color: #fff;
    border-top: 1px solid $defaultBorderColor;

    &__button {
        padding: 9px 12px;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }

        &--save-photos {
            padding: 0;
            opacity: 0;
            transition: opacity $baseTransitionTime;
            pointer-events: none;

            &.active {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    &__buttons {
        text-align: right;
        transition: opacity $baseTransitionTime;
    }

    &__text {
        color: $defaultGrayColor;
        font-weight: 500;
        font-size: 0.625em;
        line-height: 1.1;
    }

    &__col {
        display: inline-block;
        width: 33.3333%;

        &--images {
            width: 20%
        }

        &--actions {
            width: 46.6666%;
        }
    }
}

.panel {
    position: relative;
    min-height: 486px;

    &-title {
        margin: 0;
        padding: 7px 10px 8px 16px;
        color: $defaultColor;
        font-weight: 700;
        font-size: 1.125em;
        line-height: 1.375em;
        border-bottom: 1px solid $defaultBorderColor;
    }

    &-content {
        padding: 12px 12px 24px 20px;
    }
}

.block-title {
    position: relative;
    display: block;
    margin-bottom: 24px;
    color: $highlightBlue;
    font-weight: 700;
    font-size: 0.875em;
    line-height: 1.0625em;

    span {
        position: relative;
        z-index: 1;
        padding-right: 10px;
        background-color: #fff;
    }

    &::after {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        height: 1px;
        background-color: $defaultBorderColor;
        content: '';
    }
}

.photos {
    padding: 10px 12px 20px 24px;

    &-container {
        padding-right: 32px;
        padding-left: 23px;

        @include clearfix;

        &__no-photo {
            margin: 30px -23px 0 -23px;
            font-size: 0.75em;
        }
    }
}

.photo-item {
    position: relative;
    float: left;
    width: calc(33.333% - 8px);
    min-height: 116px;
    margin-right: 8px;
    margin-bottom: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 3px;
    cursor: pointer;

    &__button-remove {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        padding: 4px;
        background: rgba(255, 255, 255, 0.3);
        border: unset;
        border-radius: 3px;
        cursor: pointer;
        opacity: 0;
    }

    &__preview {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0 4px 2px;
        font-size: 0.6875em;
        line-height: 1;
        vertical-align: bottom;
        background: #fff;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        opacity: 0;
        transition: opacity $baseTransitionTime;

        &-text {
            display: inline-block;
            vertical-align: bottom;
        }

        &::after {
            display: inline-block;
            height: 100%;
            vertical-align: bottom;
            content: '';
        }
    }

    &--dropzone {
        text-align: center;
        border: 1px solid $defaultBorderColor;
        outline: none;
        cursor: pointer;

        &::after {
            display: inline-block;
            height: 100%;
            min-height: 116px;
            vertical-align: middle;
            content: '';
        }

        &-inner {
            display: inline-block;
            padding: 20px;
            color: $defaultGrayColor;
            font-weight: 500;
            font-size: 0.75em;
            line-height: 1;
            text-align: center;
            vertical-align: middle;

            span {
                margin-bottom: 4px;
            }
        }
    }

    &:hover {
        .photo-item__preview {
            opacity: 0.9;
        }

        .photo-item__button-remove {
            opacity: 1;
        }
    }
}

.map {
    padding: 10px 12px 20px 24px;

    &__container {
        position: relative;
        height: 340px;
        overflow: hidden;

        .map {
            &__select {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 400;
                width: 250px;
                padding: 0;
                font-size: 1rem;
                background-color: #fff;
                border: 1px solid $defaultBorderColor;
                border-radius: 0;
                box-shadow: unset;
                transform: translate(0, 0);
            }

            &__warning {
                position: relative;
                padding: 10px 48px;
                font-weight: 600;
                border-bottom: 1px solid $defaultBorderColor;

                svg {
                    fill: $highlightOrange;
                }
            }

            &__icon {
                position: absolute;
                top: 50%;
                left: 20px;
                margin: -7px 0 0 0;
            }
        }
    }

    :global(.yandex-map),
    :global(.leaflet-container) {
        height: 340px;
    }

    &__preloader {
        position: absolute;
        top: 60%;
        left: -9999px;
        width: 100%;
        margin-top: -50px;
        opacity: 0;
        transition:
            left 0s $baseTransitionTime,
            opacity $baseTransitionTime;

        &--loading {
            left: 50%;
            opacity: 1;
            transition:
                left 0s,
                opacity $baseTransitionTime;
        }
    }

    &__list {
        display: block;
        height: calc(100% - 61px);
        padding: 8px 15px;
        overflow: auto;
    }

    &__item {
        display: inline-block;
        width: 100%;
        margin: 4px 0;
        padding: 5px 8px;
        font-size: 0.75em;
        text-align: center;
        background-color: transparent;
        border: 1px solid $defaultBorderColor;
        border-radius: $defaultBorderRadius;
        cursor: pointer;
        transition:
            border-color  $baseTransitionTime,
            background-color $baseTransitionTime,
            color $baseTransitionTime;

        &:hover {
            color: #fff;
            background-color: $inputBorderFocus;
            border-color: $inputBorderFocus;
        }
    }
}

.history,
.company {
    padding: 10px 12px 20px 24px;

    &__container {
        min-height: 80px;
        max-height: 235px;
        padding: 5px 17px 5px 14px;
        overflow: hidden;
        overflow-y: auto;
        background: #fff;
        border: 1px solid $defaultBorderColor;
        border-radius: $defaultBorderRadius;
    }

    &__item {
        padding: 10px 0 14px;
        border-bottom: 1px solid $defaultBorderColor;

        &:last-child {
            border-bottom: unset;
        }
    }

    &__changes,
    &__datetime {
        display: inline-block;
        font-size: 0.75em;
        line-height: 1.5;
        vertical-align: middle;
    }

    &__changes {
        width: 75%;
        color: $defaultColor;
    }

    &__datetime {
        width: 25%;
        color: $defaultGrayColor;
    }
}

.company {
    &__container {
        font-size: 0.75em;
    }
}

.sources {
    padding: 10px 12px 20px 24px;

    &__container {
        min-height: 80px;
        max-height: 235px;
        padding: 5px 17px 5px 14px;
        overflow: hidden;
        overflow-y: auto;
        background: #fff;
        border: 1px solid $defaultBorderColor;
        border-radius: $defaultBorderRadius;

        &--with-actions {
            .source__item {
                &-updated {
                    width: 20%;
                }
            
                &-actions {
                    width: 5%;
                }
            }
        }
    }

    &__add {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 16px;
        margin-top: 20px;
    }

    &__label {
        font-size: 0.75em;
    }
}

.source__item {
    padding: 20px 0 24px;
    border-bottom: 1px solid $defaultBorderColor;

    &:last-child {
        border-bottom: unset;
    }

    &-link {
        padding: 4px 8px;
        line-height: 1;
    }

    &-img,
    &-link-container,
    &-posted,
    &-updated,
    &-actions {
        display: inline-block;
        vertical-align: middle;
    }

    &-img,
    &-link-container,
    &-posted,
    &-updated {
        width: 25%;
    }

    &-delete {
        background-color: transparent;
        border-color: transparent;
        outline: none;
        cursor: pointer;

        svg {
            fill: $defaultGrayColor;
        }
    }

    &-img {
        font-size: 0;

        img {
            height: 32px;
        }
    }

    &-posted,
    &-updated {
        color: $defaultGrayColor;
        font-size: 0.75em;
        line-height: 1.17;
    }
}

.approving {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: $defaultBackground;

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        font-weight: 600;
        font-size: 1.5em;
        text-align: center;
    }

    &__box {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 300px;
        transform: translate(-50%, -50%);
    }

    &__buttons {
        max-width: 258px;
        margin: 28px auto 0;
    }

    &__button {
        margin-top: 16px;
        font-weight: 400;
        font-size: 0.5em;
    }

    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
        color: $defaultGrayColor;

        svg {
            fill: $defaultGrayColor;
        }
    }
}

.similar {
    display: block;

    &__marker {
        position: absolute;
        top: 50%;
        right: 14px;
        width: 8px;
        height: 8px;
        background-color: $highlightRed;
        border-radius: 100%;
        transform: translate(0, -50%);
        opacity: 0;
        transition: opacity $baseTransitionTime;

        &--visible {
            opacity: 1;
        }
    }

    &__icon {
        display: inline-block;
        margin-left: 5px;
        vertical-align: top;
    }
}
