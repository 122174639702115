/* eslint linebreak-style: 0 */

.item-status-with-contact {
    .item-status {
        width: 498px;

        .title {
            padding-top: 10px;
            display: flex;
            justify-content: space-between;
        }
    }

    #item-status-contact-container {
        align-items: flex-start;
        flex-direction: column;

        .agency-selector {
            align-items: flex-end;
            display: flex;
            width: 100%;

            .agency-select-button {
                height: 30px;
            }
        }

        .contracts-body {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 202px;
        }
    }
    .button-add-agency {
        margin-top: 22px;
    }
}