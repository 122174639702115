@import '../../styles/variables';

.arrow {
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    background-color: $inputBackground;
    border: 1px solid $inputBorder;
    border-radius: 100%;
    cursor: pointer;
    opacity: 1;
    transition:
        opacity $baseTransitionTime,
        border-color $baseTransitionTime;

    &:hover {
        border-color: $inputBorderHover;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        margin: -6px 0 0 -3px;
        border-color: transparent transparent transparent #007bff;
        border-style: solid;
        border-width: 6px 0 6px 8px;
        content: '';
    }

    &--left {
        left: 0;

        &::before {
            margin-left: -5px;
            transform: rotate(180deg);
        }
    }

    &--right {
        right: 0;
    }

    &--disabled {
        opacity: 0.3;
    }
}

:global(.BrainhubCarousel) {
    &__dots {
        max-width: 70%;
        margin: 14px auto -4px auto !important;
        flex-wrap: wrap;

        li {
            margin: 0 2px 4px 0;
        }

        :global(.BrainhubCarousel) {
            &__dot {
                width: 16px;
                height: 16px;
                padding: 0 !important;
                background-color: $inputBackground;
                border: 2px solid $inputBorder !important;
                border-radius: 100%;
                transition:
                    border-color $baseTransitionTime,
                    background-color $baseTransitionTime;

                &:hover {
                    border-color: $inputBorderHover !important;
                }

                &--selected {
                    background-color: $inputBorderFocus;
                    border-color: $inputBorderFocus !important;

                    &:hover {
                        background-color: $inputBorderFocus;
                        border-color: $inputBorderFocus !important;
                    }
                }

                &::before {
                    content: unset !important;
                }
            }
        }
    }
}

.slider {
    position: relative;

    &--arrows {
        padding: 0 45px;
    }

    &__slide {
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &--sm {
        &.slider {
            &--arrows {
                padding: 0 24px;
            }

            .arrow {
                width: 16px;
                height: 16px;
                margin-top: -8px;

                &::before {
                    width: 0;
                    height: 0;
                    margin: -3px 0 0 -1px;
                    border-color: transparent transparent transparent #007bff;
                    border-style: solid;
                    border-width: 3px 0 3px 4px;
                    content: '';
                }

                &--left {
                    &::before {
                        margin-left: -2px;
                        transform: rotate(180deg);
                    }
                }
            }

            :global(.BrainhubCarousel) {
                &__dots {
                    margin: unset;
                    margin-top: 5px !important;
                    max-width: unset;
                    flex-wrap: unset;

                    li {
                        margin: 0 1px;
                    }

                    :global(.BrainhubCarousel) {
                        &__dot {
                            width: 8px;
                            height: 8px;
                            border: 1px solid $inputBorder !important;

                            &--selected {
                                background-color: $inputBorderFocus;
                                border-color: $inputBorderFocus !important;

                                &:hover {
                                    background-color: $inputBorderFocus;
                                    border-color: $inputBorderFocus !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__download {
        right: -16px;
        bottom: -5px;
        display: inline;
        position: absolute;

        button span {
            display: flex;
        }

        &--sm {
            right: -6px;
            bottom: -4px;

            button {
                padding: 2px;
            }
        }
    }
}
