@import '../../../styles/mixins';
@import '../../../styles/variables';

.mortgage {
    padding: 0 30px 40px 30px;
    text-align: left;

    &__hint {
        margin: -15px 0 30px 0;
        color: $defaultGrayColor;
        font-size: 0.875em;
    }

    &__fieldset {
        display: block;

        &--payment-type {
            width: 50%;
        }
    }

    &__field {
        margin-bottom: 25px;
    }

    &__total {
        font-weight: 500;
        font-size: 0.875em;
    }

    &__value {
        margin-top: 5px;
        font-size: 1.285em;

        &--month {
            color: $highlightGreen;
        }

        &--total {
            color: $highlightBlue;
        }
    }

    &__submit {
        margin: 7px 40px 32px 40px;
    }

    &__footer {
        @include clearfix;

        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        padding: 0 20px;
    }
}
