@import '../../styles/variables';

.rights {
    display: block;
    overflow: hidden;
    border: 1px solid $defaultBorderColor;
    border-radius: $defaultBorderRadius * 5;

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th {
        background-color: $rightsBackground;
    }

    tbody {
        tr {
            margin: 0;
            padding: 10px 0;
            font-size: 1rem;
            line-height: 2.2;
            border-bottom: 1px solid $defaultBorderColor;

            &:last-of-type {
                border: unset;
            }
        }
    }

    &__head {
        padding: 13px;
        font-weight: 600;
        font-size: 0.75em;
        text-align: center;
        border-bottom: 1px solid $defaultBorderColor;

        &:last-child {
            padding-right: 26px;
        }

        &--create {
            color: $highlightGreen;
        }

        &--read {
            color: $highlightBlue;
        }

        &--update {
            color: $highlightOrange;
        }

        &--delete {
            color: $highlightRed;
        }
    }

    &__td {
        text-align: center;

        &--title {
            padding-left: 26px;
            font-weight: 600;
            font-size: 0.75em;
            text-align: left;
        }
    }

    &__checkbox {
        display: inline-block;
    }
}
