@import '../../styles/variables';

.message {
    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 400;
        width: 420px;
        padding: 45px 80px;
        text-align: center;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);
        transform: translate(-50%, -50%);
    }

    &__title {
        margin: 0;
        color: $defaultColor;
        font-weight: 600;
        font-size: 1.125em;
        line-height: 1.2;
    }

    &__text {
        margin-top: 8px;
        margin-bottom: 24px;
        color: $defaultColor;
        font-size: 0.875em;
        line-height: 1.1;
        text-align: center;
    }

    &__button {
        svg {
            fill: none;
        }
    }
}
