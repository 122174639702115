@import '../../../styles/variables';
@import '../../../styles/mixins';

.header {
    @include clearfix;

    position: relative;
    max-height: 30px;
    padding: 3px 0 7px 0;
    background-color: $defaultBackground;

    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background-color: $defaultBorderColor;
        content: '';
    }

    &__sync {
        display: block;
        float: right;
    }

    &__dynamic {
        float: left;
    }

    &__action {
        position: relative;
        float: left;
        display: block;
        margin: 0  0 0 16px;
        padding: 3px 5px;
        font-size: 0.75em;
        line-height: 1.1;
        background-color: transparent;
        border: unset;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: $defaultBorderRadius;
        transition: background-color $baseTransitionTime, border-color $baseTransitionTime;

        &:disabled {
            pointer-events: none;
        }

        &::before {
            position: absolute;
            top: 6px;
            bottom: 3px;
            left: -9px;
            width: 1px;
            background-color: $defaultBorderColor;
            content: "";
        }

        &:first-of-type {
            margin-left: 0;

            &::before {
                content: none;
            }
        }

        & > * {
            display: inline-block;
            vertical-align: middle;
        }

        &:hover {
            background-color: $defaultContentBackground;
            border-color: $defaultBorderColor;
        }

        &-list {
            margin-left: -32px;
        }

        &--social {
            padding: 0;
        }

        &--social,
        &--telegram,
        &--payment {
            float: right;
            margin: 0 16px 0 0;

            b {
                font-weight: 500;
            }

            &::before {
                content: none;
            }

            &::after {
                position: absolute;
                top: 6px;
                bottom: 3px;
                right: -9px;
                width: 1px;
                background-color: $defaultBorderColor;
                content: "";
            }
        }

        &--warning {
            b {
                color: $highlightRed;
            }
        }
    }

    &__icon {
        margin-right: 10px;

        svg {
            fill: none;
        }
    }
}

.stats,
.mortgage {
    &-header {
        padding-right: 60px;
        padding-left: 60px;
        text-align: left;

        & > * {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &-icon {
        margin-left: 15px;
    }

    &-dialog {
        display: block;
    }
}

.stats-dialog {
    height: calc(100vh - 100px);
    // overflow: auto;
}

.logout {
    float: right;
}
