.popup-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 400;
    width: 0;
    height: 0;
    will-change: transform;
}

.popup-draggable {
    display: block;
}

.popup-content {
    position: relative;
    box-sizing: border-box;
    height: 80vh;
    min-height: 420px;
    background: #fff;
    border: 1px solid #d3e0ec;
    border-radius: 0 0 0 3px;
    box-shadow: 0 0 15px rgba(69, 109, 121, 0.5);
}
