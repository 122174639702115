@import '../../../styles/variables';
@import '../../../styles/mixins';

.export-objects {
    margin-top: -0px;
    padding-bottom: 30px;
    text-align: left;

    &__icon {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.75em;

        svg {
            fill: transparent;
        }
    }

    &__field {
        display: block;
        margin: 0 0 8px 0;

        &--exportAttributes,
        &--no-photo {
            margin-top: 20px;
        }

        &--ignoreIncorrect {
            color: $highlightRed;
        }

        &--fields {
            max-height: 260px;
            margin-top: 15px;
            padding: 4px 8px;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: $defaultContentBackground;
            border-radius: $defaultBorderRadius;

            & > div {
                display: block;
                margin: 0 -8px;

                & > div {
                    background-color: transparent !important;
                }
            }
        }

        &--disabled {
            pointer-events: none;
        }
    }

    &__fieldset {
        float: left;
        width: 33%;
        padding: 0 26px;
    }

    &__fieldgroup {
        margin-bottom: 2em;
    }

    &__label {
        font-size: 0.75em;

        &--fieldgroup {
            font-size: 0.875em;
            font-weight: 700;
        }
    }

    &__description {
        margin: 0px 0 20px 0;
        color: $defaultGrayColor;
        font-size: 0.75em;
        white-space: nowrap;
    }

    &__clear {
        margin-top: 12px;
        text-align: center;
    }

    &__field-list {
        @include clearfix;
    }

    &__footer {
        text-align: right;
        position: relative;
        padding: 0 26px;
    }

    &__submit {
        display: inline-block;
        width: calc(33% - 26px);
    }

    &__form {
        position: relative;
    }

    &__preloader {
        position: absolute;
        top: 0;
        left: -9999px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        z-index: 2;
        transition: opacity 0.3s, left 0.3s 0.3s;

        &--loading {
            opacity: 1;
            left: 0;
            transition: opacity 0.3s, left 0s;
        }
    }

    &__circle {
        margin: 0 auto 15px auto;

        &-wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            text-align: center;
            font-size: 1.125em;
            transform: translate(-50%, -50%);
        }
    }

    &__message {
        display: block;
        text-align: center;

        &-icon {
            display: block;
            margin: 65px auto 35px auto;
        }
    }

    &__text {
        display: block;
        margin: 25px 0;
        font-size: 1.125em;
    }

    &__actions {
        display: block;
        max-width: 190px;
        margin: 0 auto;
        margin-top: 25px;

        > * {
            & + * {
                margin-top: 10px;
            }
        }
    }

    &__link {
        display: block;
        max-width: 280px;
        margin: 25px auto;

        &-label {
            margin-bottom: 4px;
            font-size: 0.75em;
            font-weight: 700;
        }
    }
}