.chat-bot {
  &__header {
      text-align: center;
  }

  &__qr {
      width: 200px;
      margin: 16px auto;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  &__name {
      color: #a2b0bd;
      display: block;
      font-size: 16px;
  }

  &__container {
      padding-bottom: 16px;

      a {
          color: #a2b0bd;
          display: block;
          font-size: 18px;
      }
  }
}