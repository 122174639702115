@import '../../styles/variables';
@import '../../styles/mixins';

.selected {
    position: relative;
    display: block;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid $defaultBorderColor;
    border-top: unset;

    &__preloader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -9999px;
        width: 100%;
        background-color: #fff;
        opacity: 0;
        z-index: 3;
        transition:
            left 0s $baseTransitionTime,
            opacity $baseTransitionTime;

        &--loading {
            left: 0;
            opacity: 1;
            transition:
                left 0s,
                opacity $baseTransitionTime;
        }

        &--instant {
            transition:
                left 0s 0s,
                opacity 0s;
        }
    }

    &__body {
        padding: 9px 19px 9px 19px;
        background: #F2F6F9;

        &--with-footer {
            padding-bottom: 0;
        }
    }

    &__title {
        margin: 0 0 10px 0;
        color: $defaultColor;
        font-weight: 500;
        font-size: 0.875em;
        line-height: 1.2;
        display: flex;
        justify-content: space-between;
    }

    &__text {
        &--green {
            color: $highlightGreen;
        }

        &--red {
            color: $highlightRed;
        }

        &--orange {
            color: $highlightOrange;
        }

        &--blue {
            color: $highlightBlue;
        }
    }

    &__row {
        margin: 4px 0;
        position: relative;
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-gap: 0 12px;
        align-items: center;

        @include clearfix;
    }

    &__content {
        margin: 4px 0;
        display: grid;
        grid-template-columns: 80px 116px 1fr;
        grid-gap: 0 10px;
        align-items: center;
    }
    &__name,
    &__name--text,
    &__name--postfix,
    &__value {
        font-size: 0.75em;
        line-height: 1.2;
    }

    &__name {
        &--text {
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &--ellipsis {
            padding-right: 13px;
        }
    }
    &__value {
        padding-right: 10px;
        &--ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &__tooltip {
        position: absolute;
        color: $highlightBlue;
        background-color: #fff;
        font-weight: 400;
        font-size: 8.25px;
        line-height: 10px;
        padding: 1px 4px;
        border-radius: 50%;
        border: 0.75px solid $defaultBorderColor;
        cursor: pointer;
        right: -5px;
        top: 50%;
        transform: translateY(-50%);

        &--name {
            right: 0;
            top: 45%;
        }
    }
    &__hint {
        z-index: 1;
        max-width: 250px;
        margin-top: -10px !important;
        padding: 3px 6px !important;
        color: $defaultColor !important;
        font-size: 0.625em !important;
        line-height: 1.4;
        background: $defaultBackground !important;
        border-radius: $defaultBorderRadius;
        box-shadow: 0 0 4px rgba(69, 109, 121, 0.25);

        &::before,
        &::after {
            content: unset !important;
        }

        &--multiline {
            text-align: left;
        }
    }
}

.preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
    }
}
