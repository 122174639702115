@import '../../styles/variables';

.tab-list {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    border: unset;
}

.tab {
    position: relative;
    z-index: 2;
    display: inline-block;
    box-sizing: border-box;
    color: #161c24;
    font-weight: 700;
    font-size: 0.75em;
    line-height: 0.875em;
    vertical-align: bottom;
    outline: none;
    cursor: pointer;

    &-child {
        position: relative;
        z-index: 2;
        padding: 5px 25px 5px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        background: #f2f6f9;
        border: 1px solid #d3e0ec;
        border-bottom: unset;
        border-left: unset;
        border-radius: 0;
    }

    &--selected {
        z-index: 1;

        .tab-child {
            padding: 8px 25px 8px 10px;
            background: #fff;
        }

        &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1px;
            z-index: 3;
            width: 1px;
            background: #d3e0ec;
            content: '';
        }

        &::after {
            position: absolute;
            top: 2px;
            right: -5px;
            z-index: 1;
            width: 10px;
            height: 10px;
            background: $defaultGrayColor;
            transform: rotate(45deg);
            content: '';
        }

        &:first-child::before {
            content: none;
        }

        &:last-child {
            &::after {
                right: unset;
                left: -5px;
            }

            &:first-child::after {
                content: none;
            }
        }
    }

    &:first-child {
        .tab {
            &-child {
                border-left: 1px solid #d3e0ec;
                border-top-left-radius: 3px;
            }
        }
    }

    &:last-child {
        .tab {
            &-child {
                border-top-right-radius: 3px;
            }
        }
    }
}
